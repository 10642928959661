import { useCollection } from "react-firebase-hooks/firestore";
import {
  collection,
  getFirestore,
  doc,
  updateDoc,
  addDoc,
  where,
  query,
  writeBatch,
} from "firebase/firestore";

import { getApp } from "firebase/app";
import paths from "../settings/firestorePaths";
import { useReadCSV, useParseCSV } from "./useCSV";
import createTransaction from "../entities/Transaction";

const firestore = () => getFirestore(getApp());

const transactionsRef = (user) =>
  collection(firestore(), paths.transactions(user?.uid));

const useTransactions = (user, from, to) => {
  if (!from || !to) throw new Error("from and to are required");

  const queryConstraints = [];
  queryConstraints.push(where("date", ">=", from));
  queryConstraints.push(where("date", "<=", to));

  const q = query(transactionsRef(user), ...queryConstraints);

  return useCollection(q);
};

const setCategoryOnTransaction = (user, transactionId, categoryId) => {
  const path = paths.transaction(user?.uid, transactionId);
  const ref = doc(firestore(), path);
  return updateDoc(ref, {
    categoryId: categoryId ?? null,
  });
};

const useCategorizeTransaction = (user, transaction, categoryId) => {
  return Promise.all([
    setCategoryOnTransaction(user, transaction.id, categoryId),
  ]);
};

const useDeleteTransactions = (user, transactionIds) => {
  const batch = writeBatch(firestore());
  transactionIds.forEach((transactionId) => {
    const path = paths.transaction(user?.uid, transactionId);
    const ref = doc(firestore(), path);
    batch.delete(ref);
  });
  return batch.commit();
};

const useAddTransacations = async (user, transactions) => {
  const transactionsRef = collection(
    firestore(),
    paths.transactions(user?.uid)
  );
  const batch = writeBatch(firestore());
  transactions.forEach((transaction) => {
    const transactionRef = doc(transactionsRef);
    batch.set(transactionRef, transaction);
  });
  return await batch.commit();
};

const useUploadCSVTransactions = async (user, file, csvImportSettings) => {
  const csvData = await useReadCSV(file);
  const transactionData = useParseCSV(csvData, csvImportSettings);
  const transactionsWithDebits = transactionData.filter(
    (transaction) => transaction.amount > 0
  );
  const transactions = transactionsWithDebits.map((transactionData) => {
    return createTransaction({
      ...transactionData,
    });
  });
  await useAddTransacations(user, transactions);
  return transactions.length;
};

export {
  useCategorizeTransaction,
  useTransactions,
  useDeleteTransactions,
  useAddTransacations,
  useUploadCSVTransactions,
};
