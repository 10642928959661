import DefaultLayout from "../layouts/Default/DefaultLayout";
import { TextField, Button } from "@mui/material";
import React from "react";

const FeedbackPage = () => {
  return (
    <DefaultLayout title="Feedback">
      <TextField
        placeholder="Feature Requests / Questions / Comments"
        multiline
        maxRows={4}
      />
      <Button variant="contained" color="primary">
        Submit
      </Button>
    </DefaultLayout>
  );
};

export default FeedbackPage;
