import { useDocument } from "react-firebase-hooks/firestore";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { getApp } from "firebase/app";
import paths from "../settings/firestorePaths";

const getFirestoreInstance = () => getFirestore(getApp());

const settingsRef = (userId) => {
  const path = paths.user(userId);
  return doc(getFirestoreInstance(), path);
};

const useSettings = (userId) => {
  const ref = settingsRef(userId);
  return useDocument(ref);
};

const useRollApiKey = async (userId) => {
  const ref = settingsRef(userId);
  const apiKey =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  await setDoc(ref, {
    apiKey,
  });
};

const useCSVImportSettings = (userId) => {
  const path = paths.csvImportSettings(userId);
  const ref = doc(getFirestoreInstance(), path);
  return useDocument(ref);
};

const useUpdateCSVImportSettings = async (userId, data) => {
  const path = paths.csvImportSettings(userId);
  const ref = doc(getFirestoreInstance(), path);
  return await setDoc(ref, data);
};

const useHasUserConfiguredCSVSettings = (userId) => {
  const [csvImportSettings, csvImportSettingsLoading, csvImportSettingsError] =
    useCSVImportSettings(userId);

  let settingsSet = false;

  if (!csvImportSettingsLoading && !csvImportSettingsError) {
    const data = csvImportSettings.data();
    settingsSet =
      !!data &&
      data.dateColumnNo !== 0 &&
      data.amountColumnNo !== 0 &&
      data.descriptionColumnNo !== 0;
  }

  return [settingsSet, csvImportSettingsLoading, csvImportSettingsError];
};

export {
  useSettings,
  useRollApiKey,
  useCSVImportSettings,
  useUpdateCSVImportSettings,
  useHasUserConfiguredCSVSettings,
};
