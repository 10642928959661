const { object, string } = require("yup");

const createTransactionsRequestSchema = object().shape({
  email: string().email("Must be a valid email").required("Email is required"),
  password: string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

module.exports = createTransactionsRequestSchema;
