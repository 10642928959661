import React, { useMemo } from "react";
import FilterMenu from "./FilterMenu";

const TimePeriodFilters = ({
  timeRangeFilters,
  onFilterSelected,
  selectedFilter,
  ...rest
}) => {
  const filterMenuOptions = useMemo(() => {
    return timeRangeFilters.map((filter) => {
      return {
        ...filter,
        selected: filter.id === selectedFilter.id,
      };
    });
  }, [timeRangeFilters, selectedFilter]);

  return (
    <FilterMenu
      text="Dates"
      filterMenuOptions={filterMenuOptions}
      onFilterSelected={onFilterSelected}
      {...rest}
    />
  );
};

export default TimePeriodFilters;
