import React from "react";
import { Alert } from "@mui/material";
import { Snackbar } from "@mui/material";
import PropTypes from "prop-types";

const Toaster = ({ message, type, open, onClosed }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClosed}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert onClose={onClosed} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

Toaster.prototypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["error", "warning", "info", "success"]).isRequired,
};

export default Toaster;
