import { Skeleton, Box } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

const SkeletonForm = ({ numberOfRows, numberOfButtons }) => {
  const theme = useTheme();
  const rows = Array.from({ length: numberOfRows }, (_, index) => index + 1);
  const buttons = Array.from(
    { length: numberOfButtons },
    (_, index) => index + 1
  );

  return (
    <Box>
      {rows.map((item) => (
        <Skeleton
          key={item}
          variant="rectangular"
          height={50}
          sx={{ mb: theme.spacing(2) }}
        />
      ))}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {buttons.map((item) => (
          <Skeleton
            key={item}
            variant="rectangular"
            width="150px"
            height="50px"
          />
        ))}
      </Box>
    </Box>
  );
};

export default SkeletonForm;
