import { Card, Button, Skeleton } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { useSeedHouseholdCategories } from "../../hooks/useCategories";
import { useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Divider } from "@mui/material";
import InputTypes from "../shared/InputTypes";
import React from "react";
import { useAddMonthlySpendingProduct } from "../../hooks/useProducts";
import routes from "../../settings/routes";

const HouseholdSpendingCategoriesProduct = ({ disabled }) => {
  const [user, userLoading, userError] = useAuthState(getAuth());
  const navigate = useNavigate();

  const seedHouseholdCategories = useSeedHouseholdCategories(user?.uid);
  const addHouseholdSpendingProduct = useAddMonthlySpendingProduct(user?.uid);

  const onButtonClicked = async () => {
    await addHouseholdSpendingProduct();
    seedHouseholdCategories();
    const analytics = getAnalytics();
    logEvent(analytics, "seed_household_categories");
    navigate(routes.transactions);
  };

  const theme = useTheme();
  return (
    <>
      <Card>
        <CardMedia
          component="img"
          height="140"
          image="images/household.jpg"
          alt="Simple Spending Categories"
        />
        <CardContent sx={{ paddingTop: theme.spacing(1) }}>
          <InputTypes types={["zapier"]} />
          <Divider sx={{ marginBottom: theme.spacing(2) }} />
          <Typography gutterBottom variant="h2">
            Spend Tracking
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Track monthly spending. Add Trasactions and Categorize them to see
            average spend.
          </Typography>
        </CardContent>
        <CardActions>
          {!disabled && (
            <Button
              variant="contained"
              color="primary"
              onClick={onButtonClicked}
              startIcon={<AddCircleOutlineIcon />}
            >
              Add
            </Button>
          )}
          {disabled && (
            <Button
              variant="contained"
              color="primary"
              onClick={onButtonClicked}
              disabled
            >
              Product Enabled
            </Button>
          )}
        </CardActions>
      </Card>
    </>
  );
};

export default HouseholdSpendingCategoriesProduct;
