import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React from "react";

const Modal = ({ children, open, onClosed, ...rest }) => {
  const theme = useTheme();

  return (
    <Backdrop open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Box
        sx={{
          backgroundColor: theme.palette.white.main,
          borderRadius: 2,
          p: 2,
        }}
        {...rest}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            minWidth: 300,
          }}
        >
          <HighlightOffIcon
            sx={{
              cursor: "pointer",
            }}
            onClick={onClosed}
          />
        </Box>
        {children}
      </Box>
    </Backdrop>
  );
};

export default Modal;
