import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const PageSection = ({ title, children }) => {
  const theme = useTheme();

  return (
    <Box sx={{ mb: theme.spacing(3) }}>
      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default PageSection;
