import { Paper, Box } from "@mui/material";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import React from "react";
import routes from "../../settings/routes";

const SignUpSection = ({ sx }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const signUpClicked = () => {
    navigate(routes.signUp);
  };

  return (
    <Box
      sx={{
        ...sx,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        width: "100%",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={signUpClicked}
        sx={{
          fontSize: theme.typography.fontSize * 1.75,
        }}
      >
        Get Started - FREE
      </Button>
    </Box>
  );
};

export default SignUpSection;
