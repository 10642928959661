import DefaultLayout from "../layouts/Default/DefaultLayout";
import React, { useMemo, useState } from "react";
import { Paper, Typography, Box, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import WarningCard from "../components/shared/WarningCard";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { useHasUserConfiguredCSVSettings } from "../hooks/useSettings";
import CSVFileUpload from "../components/shared/CSVFileUpload";
import Toaster from "../components/shared/Toaster";

const CSVSettingsNotConfiguredWarning = () => {
  return (
    <WarningCard>
      <Box>
        <Typography variant="body1" gutterBottom>
          Before you can upload a CSV file, configure which columns to take the
          values from
        </Typography>
        <Link to="/settings#csv-import">Configure CSV Settings Here</Link>
      </Box>
    </WarningCard>
  );
};

const TransactionsPage = () => {
  const theme = useTheme();

  const [uploadSuccessfulToasterOpen, setUploadSuccessfulToasterOpen] =
    useState(false);
  const [uploadResult, setUploadResult] = useState(null);
  const [user, userLoading, userError] = useAuthState(getAuth());
  const [
    csvImportSettingsExist,
    csvImportSettingsLoading,
    csvImportSettingsError,
  ] = useHasUserConfiguredCSVSettings(user?.uid);

  const csvSettingsNotConfigured = useMemo(() => {
    return !csvImportSettingsLoading && !csvImportSettingsExist;
  }, [csvImportSettingsLoading, csvImportSettingsExist]);

  const handleTransactionsAdded = (result) => {
    setUploadResult(result);
    setUploadSuccessfulToasterOpen(true);
  };

  const handleUploadSuccessfulToasterClosed = () => {
    setUploadSuccessfulToasterOpen(false);
  };

  return (
    <DefaultLayout title="Transactions">
      <Toaster
        message={`Successfully uploaded ${uploadResult?.numberOfTransactionsAdded} transactions from ${uploadResult?.fileName}`}
        open={uploadSuccessfulToasterOpen}
        onClosed={handleUploadSuccessfulToasterClosed}
      />
      <Paper
        sx={{
          p: theme.spacing(2),
        }}
      >
        {csvSettingsNotConfigured && <CSVSettingsNotConfiguredWarning />}
        <Typography variant="h2">Import from CSV</Typography>
        <Divider sx={{ mb: theme.spacing(2) }} />
        <CSVFileUpload
          actionDisabled={csvSettingsNotConfigured}
          onTransactionsAdded={handleTransactionsAdded}
        />
      </Paper>
    </DefaultLayout>
  );
};

export default TransactionsPage;
