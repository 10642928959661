import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { CircularProgress, Container, Box } from "@mui/material";
import React from "react";
import { useHasActiveSubscription } from "../hooks/useSubscriptions";

const RouteLayout = ({ children }) => {
  return (
    <Container>
      <Box sx={{ display: "flex", justifyContent: "center", mt: "50px" }}>
        {children}
      </Box>
    </Container>
  );
};

const LoadingView = () => (
  <RouteLayout>
    <CircularProgress color="secondary" />
  </RouteLayout>
);

const ErrorView = () => (
  <RouteLayout>
    <h1>Something went wrong</h1>
  </RouteLayout>
);

const useGetSubscriptionStatus = () => {
  const [user, loading, error] = useAuthState(getAuth(getApp()));
  return useHasActiveSubscription(user?.uid);
};

const GatedContent = ({ redirectPath = "/", children }) => {
  const [
    hasActiveSubscription,
    hasActiveSubscriptionLoading,
    activeSubscriptionError,
  ] = useGetSubscriptionStatus();

  if (hasActiveSubscriptionLoading) {
    return <LoadingView />;
  } else if (!hasActiveSubscription) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

const GatedRedirect = ({ subscribedRoute = "/", unsubscribedRoute = "/" }) => {
  const [
    hasActiveSubscription,
    hasActiveSubscriptionLoading,
    activeSubscriptionError,
  ] = useGetSubscriptionStatus();

  if (hasActiveSubscriptionLoading) {
    return <LoadingView />;
  } else if (!hasActiveSubscriptionLoading && hasActiveSubscription) {
    return <Navigate to={subscribedRoute} replace />;
  } else if (!hasActiveSubscriptionLoading && !hasActiveSubscription) {
    return <Navigate to={unsubscribedRoute} replace />;
  }
};

export { GatedContent, GatedRedirect };
