import * as React from "react";
import Menu from "../../components/menu/SignedInMenu";
import { Box, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

const MobileDrawer = ({ open, onDrawClosed }) => {
  return (
    <React.Fragment>
      <Drawer anchor="left" open={open} onClose={() => onDrawClosed()}>
        <Box sx={{ marginTop: "64px" }}>
          <Menu />
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

const RegularDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    [theme.breakpoints.up("xs")]: {
      width: 0,
    },
    [theme.breakpoints.up("sm")]: {
      width: 250,
    },
  },
}));

export { MobileDrawer, RegularDrawer };
