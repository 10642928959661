import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import React from "react";

const inputTypeOptions = ["zapier", ".csv", ".qfx"];

const ZapierLogo = ({ ...rest }) => {
  return (
    <Box {...rest}>
      <img width="80" src="./images/zapier-small.png" alt="Zapier Logo" />
    </Box>
  );
};

const InputTypes = ({ types, sx }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "right",
        flexDirection: "row",
        fontSize: "1.2rem",
        fontFamily: "Montserrat,sans-serif",
        fontWeight: "300",
        ...sx,
      }}
    >
      {types.map((type, index) => {
        if (type === "zapier") {
          return (
            <ZapierLogo
              key={index}
              sx={{
                position: "relative",
                top: "4px",
                marginRight: theme.spacing(1),
              }}
            />
          );
        }

        return (
          <Box key={index} sx={{ marginRight: theme.spacing(1) }}>
            {type}
          </Box>
        );
      })}
    </Box>
  );
};

export default InputTypes;
export { inputTypeOptions };
