import React from "react";
import { Formik, Form, Field } from "formik";
const loginCredentialsSchema = require("../../vaildation/loginCredentialsSchema");
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { getApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useTheme } from "@mui/material/styles";
import { getAnalytics, logEvent } from "firebase/analytics";

const EmailPasswordSignUp = ({ onSignUpSuccessful, onSignUpFailed }) => {
  const theme = useTheme();
  const auth = getAuth(getApp());

  const signUpEmail = (email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        onSignUpSuccessful();
        const analytics = getAnalytics();
        logEvent(analytics, "sign_in", {
          method: "Email",
        });
      })
      .catch((error) => {
        onSignUpFailed();
      });
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={loginCredentialsSchema}
    >
      {({ errors, touched, values }) => (
        <Form>
          <Field name="email">
            {({ field }) => (
              <TextField
                {...field}
                id="email"
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                error={errors.email && touched.email}
                helperText={errors.email}
              />
            )}
          </Field>

          <Field name="password">
            {({ field }) => (
              <TextField
                {...field}
                sx={{ mt: 0, mb: theme.spacing(3) }}
                id="password"
                label="Password"
                variant="outlined"
                type="password"
                margin="normal"
                fullWidth
                error={errors.password && touched.password}
                helperText={errors.password}
              />
            )}
          </Field>

          <Button
            disabled={
              values.email === "" ||
              values.password === "" ||
              !!errors.email ||
              !!errors.password
            }
            aria-label="Save"
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              signUpEmail(values.email, values.password);
            }}
          >
            Continue
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default EmailPasswordSignUp;
