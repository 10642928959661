import CategorizePage from "../pages/Categorize/CategorizePage";
import HomePage from "../pages/HomePage";
import SettingsPage from "../pages/Settings/SettingsPage";
import NotFoundPage from "../pages/NotFoundPage";
import { GatedContent } from "./SubscriptionGatedContent";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import FeedbackPage from "../pages/FeedbackPage";
import TransactionsPage from "../pages/TransactionsPage";
import ZapierIntegration from "../pages/ZapierIntegration";
import SignUpPage from "../pages/SignUpPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import ZapierConnectionsPage from "../pages/ZapierConnectionsPage";
import React from "react";
import MonthlySpendReport from "../pages/Reports/MonthlySpendReport";
import HelpPage from "../pages/HelpPage";
import SubscribePage from "../pages/SubscriptionPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import TermsOfServicePage from "../pages/TermsOfServicePage";
import SignInPage from "../pages/SignInPage";
import uriRoutes from "../settings/routes";
import YNABComparison from "../pages/YNABComaprison";

const protect = ({ ...children }) => <GatedContent>{children}</GatedContent>;

const routes = [
  {
    path: uriRoutes.home,
    element: <HomePage />,
  },
  {
    path: uriRoutes.dashboard,
    element: protect(<DashboardPage />),
  },
  {
    path: uriRoutes.transactions,
    element: protect(<TransactionsPage />),
  },
  {
    path: uriRoutes.categorize,
    element: protect(<CategorizePage />),
  },
  {
    path: uriRoutes.settings,
    element: protect(<SettingsPage />),
  },
  {
    path: uriRoutes.reports,
    element: protect(<MonthlySpendReport />),
  },
  {
    path: uriRoutes.feedback,
    element: protect(<FeedbackPage />),
  },

  {
    path: uriRoutes.zapierConnections,
    element: protect(<ZapierConnectionsPage />),
  },
  {
    path: uriRoutes.subscribe,
    element: <SubscribePage />,
  },
  {
    path: uriRoutes.zapierIntegration,
    element: <ZapierIntegration />,
  },
  {
    path: uriRoutes.signUp,
    element: <SignUpPage />,
  },
  {
    path: uriRoutes.resetPassword,
    element: <ResetPasswordPage />,
  },
  {
    path: uriRoutes.help,
    element: <HelpPage />,
  },
  {
    path: uriRoutes.privacyPolicy,
    element: <PrivacyPolicyPage />,
  },
  {
    path: uriRoutes.termsOfService,
    element: <TermsOfServicePage />,
  },
  {
    path: uriRoutes.signIn,
    element: <SignInPage />,
  },
  {
    path: uriRoutes.ynabComparison,
    element: <YNABComparison />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export default routes;
