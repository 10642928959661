import { useTheme } from "@mui/material/styles";
import { Autocomplete, TextField, Box } from "@mui/material";
import { useEffect, useState } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import React from "react";
import { useMemo } from "react";

const CategoryPicker = ({
  categories,
  selectedCategoryId,
  onCategoryChanged,
  ...rest
}) => {
  const theme = useTheme();
  const [valid, setValid] = useState(false);

  const categoryChangedHandler = (event, value) => {
    const categoryId = value?.id ?? undefined;
    onCategoryChanged(categoryId);
  };

  const selectedCategory = useMemo(() => {
    if (!selectedCategoryId) return null;
    return categories.find((category) => category.id === selectedCategoryId);
  }, [categories, selectedCategoryId]);

  useEffect(() => {
    setValid(!!selectedCategoryId);
  }, [selectedCategoryId]);

  return (
    <Box sx={{ position: "relative" }}>
      <DoubleArrowIcon
        color="error"
        sx={{
          position: "absolute",
          top: "15px",
          left: "-12px",
          display: valid ? "none" : "block",
        }}
      />
      <Autocomplete
        {...rest}
        value={selectedCategory}
        options={categories}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label="Category" />}
        onChange={categoryChangedHandler}
      />
    </Box>
  );
};

export default CategoryPicker;
