import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { Paper } from "@mui/material";
import * as React from "react";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";

const SearchBar = ({ placeholderText, onTextChanged, onCleared }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();

  const clearClicked = (e, a) => {
    e.preventDefault();
    setSearchTerm("");
    onTextChanged("");
  };

  const clearButtonStyle =
    searchTerm.length > 0
      ? { visibility: "visible" }
      : { visibility: "hidden" };

  return (
    <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholderText}
        inputProps={{ "aria-label": "search google maps" }}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          onTextChanged(e.target.value);
        }}
      />
      <IconButton
        type="button"
        sx={{
          p: theme.spacing(1),
          color: theme.palette.secondary.main,
          ...clearButtonStyle,
        }}
        aria-label="clear"
        onClick={clearClicked}
      >
        <Close />
      </IconButton>
      <IconButton
        type="button"
        sx={{ p: theme.spacing(1) }}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
