import React from "react";
import { Button, Menu, MenuItem, Box, ListItemIcon } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getAnalytics, logEvent } from "firebase/analytics";

const MenuOption = ({ selected, onClickHandler, children }) => {
  return (
    <MenuItem selected={selected} onClick={onClickHandler}>
      <ListItemIcon>
        <CalendarMonthIcon />
      </ListItemIcon>
      {children}
    </MenuItem>
  );
};

const FilterMenu = (props) => {
  const {
    onFilterSelected,
    selected,
    filterMenuOptions,
    text = "Filters",
    ...rest
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const analytics = getAnalytics();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterSelected = (filter) => {
    logEvent(analytics, "filter_selected", {
      filter: filter.id,
    });
    onFilterSelected(filter);
    handleClose();
  };

  return (
    <Box>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        startIcon={<FilterListIcon />}
        {...rest}
      >
        {text}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {filterMenuOptions.map((filterMenuOption) => {
          return (
            <MenuOption
              key={filterMenuOption.id}
              selected={filterMenuOption.selected}
              onClickHandler={() => handleFilterSelected(filterMenuOption)}
            >
              {filterMenuOption.name}
            </MenuOption>
          );
        })}
      </Menu>
    </Box>
  );
};

export default FilterMenu;
