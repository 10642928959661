import { Box } from "@mui/material";
import React from "react";

const DeleteTransactionsBox = ({ children }) => (
  <Box
    display={{ xs: "flex", md: "none" }}
    sx={{
      border: 1,
      borderRadius: 1,
      p: 1,
      borderColor: "divider",
      display: {
        xs: "flex",
        md: "none",
      },
    }}
  >
    {children}
  </Box>
);

export default DeleteTransactionsBox;
