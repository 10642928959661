import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import DefaultLayout from "../layouts/Default/DefaultLayout";
import CancelIcon from "@mui/icons-material/Cancel";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import SignUpSection from "../components/homepage/SignUpSection";

const YNABComparison = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <DefaultLayout title="YouNeedABudget.com Feature Compare">
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Feature</TableCell>
                  <TableCell>MoneyTool.io</TableCell>
                  <TableCell>YouNeedABudget.com</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Yearly Price</TableCell>
                  <TableCell>
                    <Typography variant="h6" color="primary">
                      $19.99
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" color="primary">
                      $99.99
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>See where you are spending</TableCell>
                  <TableCell>
                    <CheckBoxOutlinedIcon sx={{ color: "primary.main" }} />
                  </TableCell>
                  <TableCell>
                    <CheckBoxOutlinedIcon sx={{ color: "primary.main" }} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Automatic Transaction Import from Bank</TableCell>
                  <TableCell>
                    <CancelIcon
                      sx={{
                        color: "green",
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{ display: "flex" }}
                      component="span"
                    >
                      Does not violate your banks Terms and Conditions
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <GppMaybeIcon sx={{ color: theme.palette.error.main }} />
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.error.main, display: "flex" }}
                      component="span"
                    >
                      Violates your banks Terms and Conditions
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Data Import</TableCell>
                  <TableCell>.CSV, zapier</TableCell>
                  <TableCell>.CSV, zapier, .QFX, .OFX, .QIF</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Transaction Types</TableCell>
                  <TableCell>Debits</TableCell>
                  <TableCell>Credits, Debits</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Create budgets</TableCell>
                  <TableCell>
                    <CancelIcon
                      sx={{
                        color: theme.palette.grey[500],
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <CheckBoxOutlinedIcon sx={{ color: "primary.main" }} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Automatic Categorize</TableCell>
                  <TableCell>
                    <CancelIcon
                      sx={{
                        color: theme.palette.grey[500],
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <CheckBoxOutlinedIcon sx={{ color: "primary.main" }} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Custom categories</TableCell>
                  <TableCell>
                    <CheckBoxOutlinedIcon sx={{ color: "primary.main" }} />
                  </TableCell>
                  <TableCell>
                    <CheckBoxOutlinedIcon sx={{ color: "primary.main" }} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Import from multiple accounts</TableCell>
                  <TableCell>
                    <CheckBoxOutlinedIcon sx={{ color: "primary.main" }} />
                  </TableCell>
                  <TableCell>
                    <CheckBoxOutlinedIcon sx={{ color: "primary.main" }} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <SignUpSection sx={{ mb: theme.spacing(2) }} />
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};

export default YNABComparison;
