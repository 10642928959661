import React from "react";
import MoonBeamBox from "../components/MoonBeamBox";
import { Typography, useTheme } from "@mui/material";
import SignInOptions from "../components/authorization/SignInOptions";
import DefaultLayout from "../layouts/Default/DefaultLayout";

const MoonBeamBoxContainer = () => {
  const theme = useTheme();
  return (
    <DefaultLayout>
      <MoonBeamBox
        width={{
          xs: "100%",
          md: "60%",
          lg: "40%",
        }}
      >
        <Typography
          variant="h2"
          align="center"
          pt={theme.spacing(2)}
          pb={theme.spacing(2)}
        >
          Sign in
        </Typography>
        <SignInOptions />
      </MoonBeamBox>
    </DefaultLayout>
  );
};

const SignInPage = () => {
  return <MoonBeamBoxContainer />;
};

export default SignInPage;
