import * as React from "react";
import Typography from "@mui/material/Typography";
import settings from "../../settings/settings";
import routes from "../../settings/routes";
import { Link } from "react-router-dom";

const RebudgetLogoText = () => {
  return (
    <Link
      to={routes.home}
      style={{
        textDecoration: "none",
      }}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{
          fontFamily: ["Montserrat", "sans-serif"].join(","),
          letterSpacing: "-1px",
          color: "#f7f6f3",
        }}
      >
        {settings.appName}
      </Typography>
    </Link>
  );
};

export default RebudgetLogoText;
