import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./settings/theme";
import CssBaseline from "@mui/material/CssBaseline";
import routes from "./routes/routes";
import initializeFirebase from "./environment/firebase/initialize";
import "firebaseui/dist/firebaseui.css";
import ModalContext from "./context/modalContext";
import AppContext from "./context/appContext";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { useProducts } from "./hooks/useProducts";
import { allTimeFilters } from "./factories/timeRangeFiltersFactory";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useHasActiveSubscription } from "./hooks/useSubscriptions";

const root = ReactDOM.createRoot(document.getElementById("root"));

const Pages = ({ router }) => {
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const [manageCategoriesOpen, setManageCategoriesOpen] = useState(false);
  const [deleteTransactionsOpen, setDeleteTransactionsOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [user, userLoading, userError] = useAuthState(getAuth());
  const [enabledProducts, enabledProductsLoading, enabledProductsError] =
    useProducts(user?.uid);
  const initialFilter = allTimeFilters.find((filter) => filter.selected);
  const [dateFilter, setDateFilter] = useState(initialFilter);
  const [
    hasActiveSubscription,
    hasActiveSubscriptionLoading,
    hasActiveSubscriptionError,
  ] = useHasActiveSubscription(user?.uid);

  useEffect(() => {
    const currentProducts = enabledProducts?.docs || [];
    setProducts(currentProducts);
  }, [enabledProducts]);

  return (
    <AppContext.Provider
      value={{
        products,
        dateFilter,
        setDateFilter,
        isSubscribed: hasActiveSubscription,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ModalContext.Provider
          value={{
            addCategoryOpen,
            setAddCategoryOpen,
            manageCategoriesOpen,
            setManageCategoriesOpen,
            deleteTransactionsOpen,
            setDeleteTransactionsOpen,
          }}
        >
          <CssBaseline />
          <RouterProvider router={router} />
        </ModalContext.Provider>
      </LocalizationProvider>
    </AppContext.Provider>
  );
};

const App = () => {
  initializeFirebase();
  const router = createBrowserRouter(routes);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Pages router={router} />
      </ThemeProvider>
    </React.StrictMode>
  );
};

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
