// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDThbAFax4WYC2CUHGBlP4a-eMAWwo2rDg",
  authDomain: "moneytool.io",
  projectId: "rebudget-edb58",
  storageBucket: "rebudget-edb58.appspot.com",
  messagingSenderId: "193204848737",
  appId: "1:193204848737:web:eb8e7e7129271449ef8220",
  measurementId: "G-CZZ118JQF2",
};

const initializeEnvironment = () => {
  const isDevEnvironment = process.env.NODE_ENV === "development";

  if (isDevEnvironment) {
    console.log("Running in development environment - initializing emulators");
    const db = getFirestore();
    connectFirestoreEmulator(db, "localhost", 8080);
  } else {
    console.log("Running in production environment - initializing Firebase");
  }
};

const initializeFirebase = () => {
  console.log("Initializing Firebase...");
  // Initialize Firebase Fundamentals
  initializeApp(firebaseConfig);
  initializeEnvironment();
  console.log("Firebase initialized.");
};

export default initializeFirebase;
