import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { amber } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { Warning } from "@mui/icons-material";

const WarningCard = ({ children }) => {
  const theme = useTheme();

  const cardStyle = {
    backgroundColor: amber[50],
    color: amber[800],
    mb: theme.spacing(2),
  };

  return (
    <Card sx={cardStyle}>
      <CardContent>
        <Typography variant="h6" component="h2" gutterBottom>
          <Warning
            sx={{
              fontSize: "1.5rem",
              verticalAlign: "middle",
              marginRight: theme.spacing(1),
            }}
          />
          Warning
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

export default WarningCard;
