import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { Box, Paper } from "@mui/material";
import * as React from "react";
import SearchBar from "../shared/textfields/SearchBar";
import { Typography, Divider, List, ListItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from '@mui/icons-material/Edit';
import { getAnalytics, logEvent } from "firebase/analytics";

const CrudList = ({
  onItemDeleted,
  items,
  onItemEdited,
  searchPlaceholderText = "Search",
  ...rest
}) => {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  const handleDelete = (item) => {
    onItemDeleted(item);
    const analytics = getAnalytics();
    logEvent(analytics, "item_deleted", {
      name: item.name,
    });
  };

  useEffect(() => {
    const filteredItems = items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filteredItems);
  }, [searchTerm, items]);

  const handleFilterTextChanged = (value) => {
    setSearchTerm(value);
    const analytics = getAnalytics();
    logEvent(analytics, "filter_text_changed");
  };

  const handleFilterCleared = () => {
    setFilteredItems(items);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: theme.spacing(2),
      }}
      {...rest}
    >
      <SearchBar
        placeholderText={searchPlaceholderText}
        onTextChanged={handleFilterTextChanged}
        onCleared={handleFilterCleared}
      />

      <Paper sx={{ mt: theme.spacing(2) }}>
        <List
          component="nav"
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {filteredItems.length > 0 &&
            filteredItems.map((item) => (
              <ListItem
                key={item.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography>{item.name}</Typography>

                <Box sx={{ ml: "auto" }}>
                  {/* <EditIcon sx={{
                                    color: theme.palette.neutral.dark,
                                    cursor: 'pointer',
                                    fontSize: 30
                                }} /> */}
                  <DeleteIcon
                    sx={{
                      color: theme.palette.error.main,
                      cursor: "pointer",
                      fontSize: 30,
                    }}
                    onClick={() => handleDelete(item)}
                  />
                </Box>
                <Divider />
              </ListItem>
            ))}

          {filteredItems.length === 0 && (
            <Typography sx={{ p: theme.spacing(2) }}>
              No items match your search criteria
            </Typography>
          )}
        </List>
      </Paper>
    </Box>
  );
};

export default CrudList;
