import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { getApp } from "firebase/app";
import paths from "../settings/firestorePaths";

const products = {
  monthlySpending: "monthly-spending",
};

const getFirestoreInstance = () => getFirestore(getApp());

const useAddMonthlySpendingProduct = (userId) => () => {
  const firestore = getFirestoreInstance();
  const path = paths.user(userId);

  const userRef = doc(firestore, path);
  const productsRef = collection(userRef, "products");
  const monthlySpendingRef = doc(productsRef, "monthly-spending");

  setDoc(monthlySpendingRef, {
    name: products.monthlySpending,
  });
};

const useMonthlySpendingProduct = (userId) => {
  const path = paths.monthlySpendingProduct(userId);
  const ref = doc(getFirestoreInstance(), path);
  return useDocument(ref);
};

const useProducts = (userId) => {
  const path = paths.products(userId);
  const ref = collection(getFirestoreInstance(), path);
  return useCollection(ref);
};

export { useAddMonthlySpendingProduct, useMonthlySpendingProduct, useProducts };
