import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTheme } from "@mui/material/styles";
import { useContext } from "react";
import AppContext from "../../context/appContext";
import HelpIcon from "@mui/icons-material/Help";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import routes from "../../settings/routes";
import { useSignOut } from "../../hooks/useSiteNavigation";

const Menu = () => {
  const theme = useTheme();
  const { isSubscribed } = useContext(AppContext);
  const handleSignOutClicked = useSignOut();

  return (
    <>
      {!isSubscribed && (
        <ListItemButton component={Link} to={routes.subscribe}>
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText primary="Subscribe" />
        </ListItemButton>
      )}

      <ListItemButton component={Link} to={routes.help}>
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary="Help" />
      </ListItemButton>

      <Divider />

      <Divider />
      <ListItemButton
        sx={{ mt: theme.spacing(5) }}
        component={Link}
        onClick={handleSignOutClicked}
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Sign Out" />
      </ListItemButton>
    </>
  );
};

export default Menu;
