import { Typography, TextField, Button, Skeleton, Box } from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import LoopIcon from "@mui/icons-material/Loop";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { useSettings, useRollApiKey } from "../../hooks/useSettings";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import SkeletonForm from "../../components/shared/SkeletonForm";

const LoadingView = () => <SkeletonForm numberOfRows={2} numberOfButtons={1} />;

const LoadedView = ({ user, apiKey, onButtonClicked }) => {
  const copyContent = (content) => {
    navigator.clipboard.writeText(content);

    // Show the tooltip
    // Create a timer to close it after 2 seconds
    setCopyToolTipOpen(true);
    setTimeout(() => {
      setCopyToolTipOpen(false);
    }, 2000);
  };
  const theme = useTheme();
  const [copyToolTipOpen, setCopyToolTipOpen] = React.useState(false);
  return (
    <>
      <TextField
        margin="normal"
        label="User Id"
        variant="outlined"
        fullWidth
        value={user?.uid}
        InputProps={{
          readOnly: true,
        }}
      />
      <FormControl margin="normal" fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Api Key</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={"text"}
          value={apiKey}
          onChange={() => {}}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => copyContent(apiKey)}
                edge="end"
              >
                <Tooltip title="Content Copied!" arrow open={copyToolTipOpen}>
                  <ContentCopyIcon />
                </Tooltip>
              </IconButton>
            </InputAdornment>
          }
          label="Api Key"
        />
      </FormControl>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: theme.spacing(2),
        }}
      >
        <Button variant="contained" color="primary" onClick={onButtonClicked}>
          <LoopIcon sx={{ mr: theme.spacing(1) }} />
          Generate New Key
        </Button>
      </Box>
    </>
  );
};

const ApiKey = () => {
  const [user, userLoading, userError] = useAuthState(getAuth());
  const [settings, settingsLoading, settingsError] = useSettings(user?.uid);
  const rollApiKey = () => useRollApiKey(user?.uid);
  const theme = useTheme();

  const apiKey = useMemo(() => {
    return !settings?.data()?.apiKey ? "" : settings?.data()?.apiKey;
  }, [settings]);

  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mb: theme.spacing(2) }}
      >
        Provide this key to a third party to allow them to modifu data on your
        behalf. Change the key if you suspect it has been compromised or you no
        longer wish to allow access.
      </Typography>
      {(userLoading || settingsLoading) && <LoadingView />}
      {!userLoading && !settingsLoading && !!user && !!settings && (
        <LoadedView user={user} apiKey={apiKey} onButtonClicked={rollApiKey} />
      )}
    </>
  );
};

export default ApiKey;
