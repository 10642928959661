import React from "react";

const ModalContext = React.createContext({
  addCategoryOpen: false,
  setAddCategoryOpen: () => {},
  manageCategoriesOpen: false,
  setManageCategoriesOpen: () => {},
  deleteTransactionsOpen: false,
  setDeleteTransactionsOpen: () => {},
});

export default ModalContext;
