const createTransaction = ({ description, date, amount }) => {
  if (!description) {
    throw new Error("Description name cannot be empty.");
  }

  if (description.length > 50) {
    throw new Error("Description cannot be longer than 50 characters");
  }

  if (!date) {
    throw new Error("Date cannot be empty.");
  }

  if (!amount) {
    throw new Error("Amount cannot be empty.");
  }

  return {
    description,
    date,
    amount,
    categoryId: null,
  };
};

export default createTransaction;
