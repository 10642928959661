import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

const Feature = ({ title, imageSource, children, sx, ...rest }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...sx,
        mb: theme.spacing(2),
        display: "flex",
        gap: theme.spacing(2),
      }}
      {...rest}
    >
      <CheckBoxOutlinedIcon
        sx={{ fontSize: 50, color: theme.palette.primary.main }}
      />
      <Box>
        <Typography variant="h2">{title}</Typography>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};

export default Feature;
