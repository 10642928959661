import { Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getAnalytics, logEvent } from "firebase/analytics";
import React from "react";
import { useMemo, useState } from "react";
import FilterBar from "../shared/FilterBar";
import TimePeriodFilters from "../../components/shared/TimePeriodFilters";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import { Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import DeleteButton from "./DeleteButton";
import DeleteTransactionsBox from "./DeleteTransactionsBox";

const CategorizeFilterBar = ({
  dateFilter,
  timeRangeFilters,
  onAddCategoryOpened,
  onManageCategoriesOpened,
  onDeleteTransactionsToggled,
  categories,
  onFilterSelected,
  deleteTransactionsToggled = false,
  onDeleteTransactionsClicked,
  ...rest
}) => {
  const theme = useTheme();

  const handleCategoryAddClicked = () => {
    onAddCategoryOpened();
    const analytics = getAnalytics();
    logEvent(analytics, "add_category_clicked");
  };

  const handleManageCategoriesClicked = () => {
    onManageCategoriesOpened();
    const analytics = getAnalytics();
    logEvent(analytics, "manage_categories_clicked");
  };

  const handleDeleteTransactionsToggled = (state) => {
    const isToggled = state.target.checked;
    onDeleteTransactionsToggled(isToggled);
    const analytics = getAnalytics();
    logEvent(analytics, "delete_transactions_toggled");
  };

  const disableManageCategories = useMemo(() => {
    return categories.length === 0;
  }, [categories]);

  const selectedFiltersText = useMemo(() => {
    return dateFilter?.name;
  }, [dateFilter]);

  return (
    <FilterBar
      {...rest}
      contents={
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TimePeriodFilters
              fullWidth
              timeRangeFilters={timeRangeFilters}
              onFilterSelected={onFilterSelected}
              selectedFilter={dateFilter}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Button
              fullWidth
              aria-label="Add Category"
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleCategoryAddClicked}
            >
              Add Category
            </Button>
          </Grid>

          <Grid item xs={12} md={3}>
            <Button
              fullWidth
              aria-label="Manage Categories"
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
              onClick={handleManageCategoriesClicked}
              disabled={disableManageCategories}
            >
              Manage Categories
            </Button>
          </Grid>

          <Grid item xs={12} md={3}>
            <DeleteButton
              sx={{
                display: { xs: "none", md: "flex" },
              }}
              onClick={onDeleteTransactionsClicked}
            />
            <DeleteTransactionsBox>
              <Switch
                checked={deleteTransactionsToggled}
                onChange={handleDeleteTransactionsToggled}
              />
              <DeleteButton
                disabled={!deleteTransactionsToggled}
                onClick={onDeleteTransactionsClicked}
              />
            </DeleteTransactionsBox>
          </Grid>
        </Grid>
      }
      appliedFilters={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              marginTop: theme.spacing(1),
            }}
          >
            <CalendarMonthIcon
              sx={{
                marginRight: "2px",
                color: theme.palette.text.secondary,
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                marginTop: "6px",
                fontWeight: 700,
                fontFamily: theme.typography.brand,
              }}
            >
              {selectedFiltersText}
            </Typography>
          </Box>
        </>
      }
    ></FilterBar>
  );
};

export default CategorizeFilterBar;
