import { getAnalytics, logEvent } from "firebase/analytics";
import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const useGoogleSignIn = () => {
  const auth = getAuth(getApp());
  const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);

  return async () => {
    const result = await signInWithGoogle();
    const successful = !!result.user;

    if (successful) {
      const analytics = getAnalytics();
      logEvent(analytics, "sign_in", {
        method: "Google",
      });
    }

    return [result?.user, loading, error];
  };
};

export { useGoogleSignIn };
