import MembershipTypes from "../components/membership/MembershipTypes";
import DefaultLayout from "../layouts/Default/DefaultLayout";
import React, { useMemo } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

const FeatureSet = ({ title, children }) => {
  const theme = useTheme();
  return (
    <Box sx={{ mt: theme.spacing(2) }}>
      <Typography variant="h2">{title}</Typography>
      <Divider />
      <Box sx={{ pt: theme.spacing(2) }}>{children}</Box>
    </Box>
  );
};

const FeatureItem = ({ text }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        gap: theme.spacing(2),
      }}
    >
      <CheckBoxOutlinedIcon
        sx={{
          color: theme.palette.primary.main,
        }}
      />
      <Typography
        variant="body1"
        sx={{
          fontSize: theme.typography.fontSize * 1.3,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const SubscribePage = () => {
  const theme = useTheme();
  return (
    <DefaultLayout title="Subscribe">
      <MembershipTypes />
      <FeatureSet title="Import From">
        <FeatureItem text="CSV Files" />
        <FeatureItem text="Zapier" />
      </FeatureSet>
      <FeatureSet title="Features">
        <FeatureItem text="Add custom categories for transactions" />
        <FeatureItem text="View monthly spend by Category" />
      </FeatureSet>
      <FeatureSet title="Privacy & Security">
        <FeatureItem text="Does not violate bank Terms and Conditions like competitor products" />
        <FeatureItem text="No Bank Account information saved" />
        <FeatureItem text="Built on latest industry best practices" />
        <FeatureItem text="Hosted on industry's best - Google Software and Hardware" />
      </FeatureSet>
      <FeatureSet title="Help">
        <FeatureItem text="Responsive and caring support to help you" />
      </FeatureSet>
    </DefaultLayout>
  );
};

export default SubscribePage;
