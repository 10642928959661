const user = (uid) => `users/${uid}`;
const products = (uid) => `${user(uid)}/products`;
const monthlySpendingProduct = (uid) => `${products(uid)}/monthly-spending`;
const category = (uid, categoryId) => `${categories(uid)}/${categoryId}`;
const categories = (uid) => `${monthlySpendingProduct(uid)}/categories`;
const transactions = (uid) => `${monthlySpendingProduct(uid)}/transactions`;
const transaction = (uid, transactionId) =>
  `${transactions(uid)}/${transactionId}`;
const settings = (uid) => `${monthlySpendingProduct(uid)}/settings`;
const csvImportSettings = (uid) => `${settings(uid)}/csv-import`;
const membershipProducts = "products";
const stripeCheckoutSessions = (uid) => `${user(uid)}/checkout_sessions`;
const userSubscriptions = (uid) => `${user(uid)}/subscriptions`;

const firestorePaths = {
  user,
  categories,
  products,
  monthlySpendingProduct,
  transactions,
  transaction,
  category,
  csvImportSettings,
  membershipProducts,
  stripeCheckoutSessions,
  userSubscriptions,
};

export default firestorePaths;
