const { Divider, Typography } = require("@mui/material");
import React from "react";

const Products = ({ children }) => {
  return (
    <>
      <Typography variant="h2">Products</Typography>
      <Divider sx={{ width: "100%", marginBottom: "25px" }} />
      {children}
    </>
  );
};

export default Products;
