import { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { getAnalytics, logEvent } from "firebase/analytics";
import React from "react";

const AddCategory = ({ categories, onCategoryAdded }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [valid, setValid] = useState(false);

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };

  // category name must be lowercased and unique
  useEffect(() => {
    if (categoryName.length === 0) {
      setValid(false);
    } else if (categoryName.length < 3) {
      setValid(false);
      setErrorMessage("Category name must be at least 3 characters long");
    } else if (
      categories.find(
        (category) => category.name.toLowerCase() === categoryName.toLowerCase()
      )
    ) {
      setValid(false);
      setErrorMessage("Category name already exists");
    } else {
      setValid(true);
      setErrorMessage("");
    }
  }, [categoryName]);

  const handleAddCategory = () => {
    onCategoryAdded({ name: categoryName });
    const analytics = getAnalytics();
    logEvent(analytics, "category_saved", {
      name: categoryName,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h2">Add Category</Typography>
      <TextField
        id="category-name"
        label="Category Name"
        variant="outlined"
        value={categoryName}
        onChange={handleCategoryNameChange}
        error={!valid}
        helperText={errorMessage}
        margin="normal"
      />
      <Button
        disabled={!valid}
        aria-label="Save"
        variant="contained"
        color="primary"
        onClick={handleAddCategory}
      >
        Save
      </Button>
    </Box>
  );
};

export default AddCategory;
