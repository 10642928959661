import { Box, Divider, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import routes from "../../settings/routes";
import { useGoogleSignIn } from "../../hooks/useSignIn";
import { GatedRedirect } from "../../routes/SubscriptionGatedContent";
import Toaster from "../shared/Toaster";
import EmailPasswordSignUp from "./EmailPasswordSignUp";

const SignUpOptions = ({ ...rest }) => {
  const theme = useTheme();
  const [signedInUser, setSignedInUser] = useState(null);
  const [googleSignInFailedOpen, setGoogleSignInFailedOpen] = useState(false);
  const [emailSignUpSuccessOpen, setEmailSignUpSuccessOpen] = useState(false);
  const [emailSignUpFailedOpen, setEmailSignUpFailedOpen] = useState(false);

  const signInWithGoogle = useGoogleSignIn();

  const handleSignInWithGoogle = async () => {
    const [user, loading, error] = await signInWithGoogle();
    if (!loading && !error && user) {
      setSignedInUser(user);
    } else if (!loading && error) {
      setGoogleSignInFailedOpen(true);
    }
  };

  const handleEmailSignUpSuccessful = () => {
    setEmailSignUpSuccessOpen(true);
    setSignedInUser(true);
  };

  const handleEmailSignUpFailed = () => {
    setEmailSignUpFailedOpen(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "20px",
      }}
    >
      <Button
        onClick={handleSignInWithGoogle}
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
          width: "250px",
          margin: "auto",
          color: theme.palette.neutral.medium,
          fontSize: "14px",
          textTransform: "none",
          fontWeight: 500,
          backgroundImage: "url('images/Google.png')",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "20px",
          backgroundPositionY: "7px",
          backgroundSize: "20px",
        }}
      >
        Continue with Google
      </Button>

      <Divider
        orientation="horizontal"
        flexItem
        sx={{ my: theme.spacing(3), mb: theme.spacing(1), width: "100%" }}
      />

      <EmailPasswordSignUp
        onSignUpSuccessful={handleEmailSignUpSuccessful}
        onSignUpFailed={handleEmailSignUpFailed}
      />

      <Box
        sx={{
          mt: theme.spacing(2),
          textAlign: "center",
        }}
      >
        <p>
          <Link to={routes.signIn}>Sign in instead</Link>
        </p>
        <Box sx={{ mt: theme.spacing(5) }}>
          <Typography variant="body2" color="textSecondary" align="center">
            By signing up, you agree to our{" "}
            <Link to={routes.termsOfService}>Terms</Link> and{" "}
            <Link to={routes.privacyPolicy}>Privacy Policy</Link>.
          </Typography>
        </Box>
      </Box>

      {signedInUser && (
        <GatedRedirect
          subscribedRoute={routes.dashboard}
          unsubscribedRoute={routes.subscribe}
        />
      )}

      <Toaster
        message="Successfully signed up!"
        type="success"
        open={emailSignUpSuccessOpen}
        onClosed={() => setEmailSignUpSuccessOpen(false)}
      />

      <Toaster
        message="Ran into issues creating your account."
        type="error"
        open={emailSignUpFailedOpen}
        onClosed={() => setEmailSignUpFailedOpen(false)}
      />
    </Box>
  );
};
export default SignUpOptions;
