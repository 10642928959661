import { Typography, Paper, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

const SeeWhereYourMoneyIsGoing = () => {
  const theme = useTheme();
  return (
    <Typography
      variant="hero"
      component="h1"
      align="center"
      sx={{
        [theme.breakpoints.up("xs")]: {
          fontSize: "3rem",
          lineHeight: "3rem",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "4rem",
          lineHeight: "4rem",
        },
      }}
    >
      Save.{" "}
      <span
        style={{
          background:
            "linear-gradient(260deg, #214da6 0%, #ed1c93 50%, #ed1cc0 80%),#fff",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          color: "#a371f7",
          fontWeight: 700,
        }}
      >
        MONEY.
      </span>{" "}
      Now.
    </Typography>
  );
};

export default SeeWhereYourMoneyIsGoing;
