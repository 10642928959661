import DefaultLayout from "../layouts/Default/DefaultLayout";
import settings from "../settings/settings";
import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PageSection from "../components/shared/PageSection";

const PrivacyPolicyPage = () => {
  const theme = useTheme();
  return (
    <DefaultLayout title="Terms of Service">
      <Paper sx={{ p: theme.spacing(2) }}>
        <PageSection>
          <h2>Acceptance of Terms</h2>
          <p>
            {`By accessing, signing in, or using MoneyTool.io ("the Service"), you agree to be
            bound by these Terms of Service and all applicable laws and
            regulations. If you do not agree with these terms, please refrain
            from using the Service.`}
          </p>

          <h2>Communications</h2>
          <p>
            {`By signing in or creating an account on MoneyTool.io, you agree to receive
            communications from us, including emails, notifications, and
            messages. You can opt out of receiving certain communications by
            changing your account settings or via the unsubscribe link in the
            email.`}
          </p>

          <h2>Description of Service</h2>
          <p>
            MoneyTool.io is an online financial management tool that provides
            various features and services to help users manage their finances
            effectively.
          </p>

          <h2>User Responsibilities</h2>
          <p>
            You are responsible for maintaining the confidentiality of your
            account information and for all activities that occur under your
            account. You agree to provide accurate and up-to-date information
            when using the Service.
          </p>

          <h2>Prohibited Conduct</h2>
          <p>
            You agree not to engage in any of the following prohibited
            activities:
            <ul>
              <li>Violating any applicable laws or regulations</li>
              <li>
                {`Attempting to gain unauthorized access to the Service or other
                users' accounts`}
              </li>
              <li>
                Interfering with or disrupting the Service or its servers or
                networks
              </li>
              <li>Uploading or transmitting any malicious code or viruses</li>
              <li>
                Engaging in any activity that could harm or damage the Service
                or its users
              </li>
            </ul>
          </p>

          <h2>Intellectual Property</h2>
          <p>
            MoneyTool.io and its original content, features, and functionality
            are owned by MoneyTool.io and are protected by international
            copyright, trademark, patent, trade secret, and other intellectual
            property or proprietary rights laws.
          </p>

          <h2>Limitation of Liability</h2>
          <p>
            MoneyTool.io and its affiliates, directors, employees, or agents
            shall not be liable for any indirect, incidental, special,
            consequential, or punitive damages arising out of or in connection
            with the use of the Service or these Terms of Service.
          </p>

          <h2>Disclaimer of Warranty</h2>
          <p>
            {`The Service is provided on an "as is" and "as available" basis,
            without any warranties, expressed or implied. MoneyTool.io makes no
            representations or warranties of any kind regarding the Service, its
            content, or its availability.`}
          </p>

          <h2>Modifications to the Service</h2>
          <p>
            MoneyTool.io reserves the right to modify or discontinue the
            Service, temporarily or permanently, with or without notice. You
            agree that MoneyTool.io shall not be liable to you or any third
            party for any modification, suspension, or discontinuation of the
            Service.
          </p>

          <h2>Governing Law</h2>
          <p>
            These Terms of Service shall be governed by and construed in
            accordance with the laws of Alberta, Canada, without regard to its
            conflict of laws provisions.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions, concerns, or feedback regarding these
            Terms of Service, please contact us at help@moneytool.io.
          </p>
        </PageSection>
      </Paper>
    </DefaultLayout>
  );
};

export default PrivacyPolicyPage;
