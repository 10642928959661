import DefaultLayout from "../../layouts/Default/DefaultLayout";
import React from "react";
import SettingsCard from "../../components/shared/SettingsCard";
import KeyIcon from "@mui/icons-material/Key";
import ApiKey from "./ApiKey";
import DatasetIcon from "@mui/icons-material/Dataset";
import CSVImport from "./CSVImport";
import ManageSubscription from "./ManageSubscription";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const SettingsPage = () => {
  return (
    <DefaultLayout title="Settings">
      <SettingsCard icon={<KeyIcon />} title="API Key">
        <ApiKey />
      </SettingsCard>

      <SettingsCard title="CSV Import" icon={<DatasetIcon />}>
        <CSVImport />
      </SettingsCard>
      <SettingsCard title="Subscription" icon={<CreditCardIcon />}>
        <ManageSubscription />
      </SettingsCard>
    </DefaultLayout>
  );
};

export default SettingsPage;
