import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const DropShadowImage = ({ image, alt, caption, sx }) => {
  const theme = useTheme();
  return (
    <Box {...sx}>
      <img
        src={image}
        alt={alt}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          boxShadow: "rgba(0, 0, 0, 0.3) 0 3px 9px",
          // "webkit-box-shadow": "rgba(0, 0, 0, 0.3) 0 3px 9px",
          // "moz-box-shadow": "rgba(0,0,0,0.3) 0 3px 9px",
        }}
      />
      {caption && (
        <Typography
          variant="h4"
          sx={{
            mb: theme.spacing(5),
            textAlign: "center",
            fontStyle: "italic",
            fontWeight: "lighter",
            color: theme.palette.text.secondary,
          }}
        >
          {caption}
        </Typography>
      )}
    </Box>
  );
};

export default DropShadowImage;
