import React, { useEffect, useMemo, useState } from "react";
import { Grid, Box, Paper } from "@mui/material";
import MembershipCard from "./MembershipCard";
import {
  useCreateCheckoutSession,
  useCheckoutSession,
} from "../../hooks/useSubscriptions";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { subscriptionPrices } from "../../helpers/helpers";
import Toaster from "../shared/Toaster";
import { useHasActiveSubscription } from "../../hooks/useSubscriptions";
import Loading from "../shared/Loading";
import { useTheme } from "@mui/material/styles";

const Memberships = () => {
  const theme = useTheme();
  const [user, userLoading, userError] = useAuthState(getAuth());
  const [checkoutSessionRef, setCheckoutSessionRef] = useState(null);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [
    hasActiveSubscription,
    hasActiveSubscriptionLoading,
    hasActiveSubscriptionError,
  ] = useHasActiveSubscription(user?.uid);

  const [showOverlayLoading, setShowOverlayLoading] = useState(false);

  const primaryMembershipStyles = {
    sx: {
      boxShadow: 10,
      borderRadius: 3,
      minHeight: {
        xs: 200,
        md: 300,
      },
      backgroundColor: "#fff9e2",
    },
  };

  const secondaryMembershipStyles = {
    position: {
      xs: "initial",
      md: "relative",
    },
    top: {
      xs: "initial",
      md: 20,
    },
  };

  const [checkoutSession, checkoutSessionLoading, checkoutSessionError] =
    useCheckoutSession(checkoutSessionRef);

  useEffect(() => {
    const data = checkoutSession?.data();

    if (data?.error) {
      setErrorMessageOpen(true);
      console.log(data?.error);
    }

    if (data?.url) {
      window.location.assign(data.url);
    }
  }, [checkoutSession]);

  const monthlyClicked = async () => {
    setShowOverlayLoading(true);
    const ref = await useCreateCheckoutSession(
      user?.uid,
      subscriptionPrices.monthly
    );
    setCheckoutSessionRef(ref);
  };

  const yearlyClicked = async () => {
    setShowOverlayLoading(true);
    console.log("USER ID", user?.uid);
    console.log("Yearly price", subscriptionPrices.yearly);
    const ref = await useCreateCheckoutSession(
      user?.uid,
      subscriptionPrices.yearly
    );
    setCheckoutSessionRef(ref);
  };

  const businessClicked = async () => {
    setShowOverlayLoading(true);
    const ref = await useCreateCheckoutSession(
      user?.uid,
      subscriptionPrices.business
    );
    setCheckoutSessionRef(ref);
  };

  const enableButtons = useMemo(() => {
    return (
      !!user &&
      !hasActiveSubscription &&
      !hasActiveSubscriptionLoading &&
      !hasActiveSubscriptionError
    );
  }, [
    hasActiveSubscription,
    hasActiveSubscriptionLoading,
    hasActiveSubscriptionError,
  ]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <MembershipCard
            name="Monthly"
            price={4.99}
            handleClick={monthlyClicked}
            disabled={!enableButtons}
            {...secondaryMembershipStyles}
          >
            <ul>
              <li>Cancel before 30 days for no charge</li>
              <li>Billed monthly</li>
            </ul>
          </MembershipCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <MembershipCard
            name="Yearly"
            frequency="year"
            price={19.99}
            handleClick={yearlyClicked}
            disabled={!enableButtons}
            {...primaryMembershipStyles}
          >
            <ul>
              <li>Cancel before 30 days for no charge</li>
              <li>Most popular option</li>
              <li>Billed yearly</li>
            </ul>
          </MembershipCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <MembershipCard
            name="Business"
            price={14.99}
            handleClick={businessClicked}
            disabled={!enableButtons}
            {...secondaryMembershipStyles}
          >
            <ul>
              <li>Cancel before 30 days for no charge</li>
              <li>Billed monthly</li>
              <li>Ideal for businesses</li>
            </ul>
          </MembershipCard>
        </Grid>
      </Grid>
      <Toaster
        message="An error occured creating a checkout"
        type="error"
        open={errorMessageOpen}
        onClosed={() => setErrorMessageOpen(false)}
      />
      <Loading open={showOverlayLoading} />
    </Box>
  );
};

export default Memberships;
