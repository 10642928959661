import DefaultLayout from "../layouts/Default/DefaultLayout";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import SeeWhereYourMoneyIsGoing from "../components/homepage/SeeWhereYourMoneyIsGoing";
import Testimonials from "../components/homepage/Testimonials";
import SignUpSection from "../components/homepage/SignUpSection";
import TopText from "../components/homepage/TopText";
import Outcomes from "../components/homepage/Outcomes";
import DropShadowImage from "../components/DropShadowImage";

const ImageStyle = {
  sx: {
    width: {
      xs: "100%",
      md: "80%",
    },
  },
};

const HomePage = () => {
  const theme = useTheme();

  return (
    <DefaultLayout>
      <Box
        sx={{
          mt: {
            xs: theme.spacing(2),
            sm: theme.spacing(5),
            lg: theme.spacing(5),
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SeeWhereYourMoneyIsGoing />

        <TopText />

        <DropShadowImage
          image="https://i.imgur.com/VwnQMPX.png"
          alt="Showcasing the categorization feature"
          {...ImageStyle}
        />

        <SignUpSection sx={{ mb: theme.spacing(2) }} />

        <Testimonials sx={{ mb: theme.spacing(5) }} />

        <Outcomes />

        <DropShadowImage
          image="https://i.imgur.com/VU0NFkk.png"
          alt="Monthly reporting feature"
          caption="Monthly reporting feature"
          {...ImageStyle}
        />

        <DropShadowImage
          image="https://i.imgur.com/WOSW5JC.png"
          alt="Integration with Zapier"
          caption="Integration with Zapier"
          {...ImageStyle}
        />
      </Box>
    </DefaultLayout>
  );
};

export default HomePage;
