import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

const Testimony = ({ image, name, text, ...rest }) => {
  const theme = useTheme();

  return (
    <Box {...rest}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={image}
          alt={name}
          style={{
            width: 75,
            height: 75,
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />

        <Typography
          variant="h6"
          sx={{
            ml: theme.spacing(2),
          }}
        >
          {name}
        </Typography>
      </Box>

      <Typography
        variant="body1"
        sx={{
          mt: theme.spacing(2),
          fontStyle: "italic",
          color: theme.palette.grey[600],
          textAlign: "center",
        }}
      >
        &quot;{""}
        {text}
        {""}&quot;
      </Typography>
    </Box>
  );
};

export default Testimony;
