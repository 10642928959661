import DefaultLayout from "../layouts/Default/DefaultLayout";
import React from "react";

const ZapierConnections = () => {
  return (
    <DefaultLayout title={null}>
      <zapier-full-experience
        client-id="fmjtiZHjt6tthJMw1hocV91KmxEeb3SAEo1Vblfb"
        theme="light"
        app-search-bar-display="show"
      />
    </DefaultLayout>
  );
};

export default ZapierConnections;
