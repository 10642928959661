import { Box, Grid } from "@mui/material";
import React from "react";
import Testimony from "./Testimony";

const Testimonials = ({ sx }) => {
  return (
    <Box
      sx={{
        ...sx,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "row",
          lg: "row",
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Testimony
            image="images/Testimonial1.jpg"
            name="Sarah"
            text="I thought we were spending $400 a month on food. Turns out it was $1400!! We now monitor our fast food and dining out and save an extra $700 per month."
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Testimony
            image="images/Avatar.png"
            name="Chris"
            text="We managed to find a few thousand dollars of savings for our Kids education. Thank you!"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Testimonials;
