import { HelpOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React from "react";

const MyComponent = ({ text, ...rest }) => {
  return (
    <Tooltip title={text} {...rest}>
      <HelpOutline color="primary" />
    </Tooltip>
  );
};

export default MyComponent;
