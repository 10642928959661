import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useGetStripeLink } from "../../hooks/useSubscriptions";
import { getAuth } from "firebase/auth";
import SkeletonForm from "../../components/shared/SkeletonForm";

const LoadingView = () => <SkeletonForm numberOfRows={3} numberOfButtons={1} />;

const LoadedView = ({ httpLink }) => {
  return (
    <>
      {console.log(httpLink)}
      <a href={httpLink}>Manage your subscription</a>
    </>
  );
};

const ManageSubscription = () => {
  const [user, userLoading, userError] = useAuthState(getAuth());
  const [stripeLink, stripeLinkLoading, stripeLinkError] = useGetStripeLink(
    user?.uid
  );

  return (
    <>{!userLoading && !!stripeLink && <LoadedView httpLink={stripeLink} />}</>
  );
};

export default ManageSubscription;
