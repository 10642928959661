import { parse } from "csv-parse/browser/esm/sync";

const useReadCSV = (file) => {
  const reader = new FileReader();
  if (!file) {
    return Promise.reject(new Error("No file uploaded"));
  }

  return new Promise((resolve, reject) => {
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        reject(new Error("No file uploaded"));
      }

      const { result } = evt.target;
      const records = parse(result, {
        autoParse: true,
        trim: true,
        skip_empty_lines: true,
      });

      resolve(records);
    };

    reader.readAsBinaryString(file);
  });
};

const useParseCSV = (csvData, csvImportSettings) => {
  const { dateColumnNo, descriptionColumnNo, amountColumnNo } =
    csvImportSettings;
  return csvData.map((row) => {
    const date = new Date(row[dateColumnNo - 1]);
    const description = row[descriptionColumnNo - 1];
    const amount = parseFloat(row[amountColumnNo - 1]);
    return { date, description, amount };
  });
};

export { useReadCSV, useParseCSV };
