import React from "react";
import DefaultLayout from "../layouts/Default/DefaultLayout";
import MoonBeamBox from "../components/MoonBeamBox";
import ResetPassword from "../components/authorization/ResetPassword";
import { Box } from "@mui/material";

const ResetPasswordPage = () => {
  // Should use the EmailPasswordSignUp component
  return (
    <DefaultLayout title="Reset Password">
      <MoonBeamBox>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <ResetPassword />
        </Box>
      </MoonBeamBox>
    </DefaultLayout>
  );
};

export default ResetPasswordPage;
