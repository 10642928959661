import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { useAddCategory, useDeleteCategory } from "../../hooks/useCategories";
import Modal from "../../components/Modal";
import ModalContext from "../../context/modalContext";
import { useContext } from "react";
import AddCategory from "../../components/categorize/AddCategory";
import CrudList from "../../components/crudList/CrudList";
import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Modals = ({
  addCategoryKey,
  manageCategoriesModalKey,
  deleteTransactionsModalKey,
  categories = [],
  selectedTransactions = [],
  onOkClicked,
}) => {
  const theme = useTheme();
  const [user, userLoading, userError] = useAuthState(getAuth());
  const addCategory = useAddCategory(user?.uid);
  const deleteCategory = useDeleteCategory(user?.uid);

  const {
    addCategoryOpen,
    setAddCategoryOpen,
    manageCategoriesOpen,
    setManageCategoriesOpen,
    deleteTransactionsOpen,
    setDeleteTransactionsOpen,
  } = useContext(ModalContext);

  const handleCategoryAdded = ({ name }) => {
    addCategory(name);
    setAddCategoryOpen(false);
  };

  const handleCategoryDeleted = ({ id }) => {
    deleteCategory(id);
  };

  const handleCancelClicked = () => {
    setDeleteTransactionsOpen(false);
  };

  const handleOkClicked = () => {
    setDeleteTransactionsOpen(false);
    onOkClicked();
  };

  return (
    <>
      <Modal
        open={addCategoryOpen}
        onClosed={() => setAddCategoryOpen(false)}
        key={addCategoryKey}
      >
        <AddCategory
          categories={categories}
          onCategoryAdded={handleCategoryAdded}
        />
      </Modal>

      <Modal
        open={manageCategoriesOpen}
        onClosed={() => setManageCategoriesOpen(false)}
        key={manageCategoriesModalKey}
      >
        <CrudList
          sx={{ height: "50vh", overflow: "auto" }}
          searchPlaceholderText="Filter Categories"
          items={categories}
          onItemDeleted={handleCategoryDeleted}
        />
      </Modal>

      <Modal
        open={deleteTransactionsOpen}
        onClosed={() => setDeleteTransactionsOpen(false)}
        key={deleteTransactionsModalKey}
      >
        <Typography variant="h2" gutterBottom>
          Delete Transactions
        </Typography>
        <Typography
          variant="body1"
          sx={{
            pb: theme.spacing(5),
          }}
        >
          Really delete {selectedTransactions.length} transactions?
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: theme.spacing(1),
          }}
        >
          <Button
            aria-label="OK"
            variant="contained"
            color="primary"
            onClick={handleOkClicked}
          >
            OK
          </Button>
          <Button
            aria-label="Cancel"
            variant="outlined"
            onClick={handleCancelClicked}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Modals;
