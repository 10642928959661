import DefaultLayout from "../../layouts/Default/DefaultLayout";
import Categorize from "../../components/categorize/Categorize";
import { useMemo, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { useCategories } from "../../hooks/useCategories";
import {
  useTransactions,
  useDeleteTransactions,
} from "../../hooks/useTransactions";
import { unpack } from "../../helpers/helpers";
import ModalContext from "../../context/modalContext";
import { useContext, useState } from "react";
import Modals from "./CategorizeModals";
import React from "react";
import { useTheme } from "@mui/material/styles";
import CategorizeFilterBar from "../../components/categorize/CategorizeFilterBar";
import { allTimeFilters } from "../../factories/timeRangeFiltersFactory";
import AppContext from "../../context/appContext";

const CategorizePage = () => {
  const { dateFilter, setDateFilter } = useContext(AppContext);
  const theme = useTheme();
  const [user, userLoading, userError] = useAuthState(getAuth());
  const [addCategoryModalKey, setAddCategoryModalKey] = useState(0);
  const [manageCategoriesModalKey, setManageCategoriesModalKey] =
    useState(10000);
  const [selectedTransactions, setSelectedTransactions] = useState([]);

  const {
    setAddCategoryOpen,
    setManageCategoriesOpen,
    setDeleteTransactionsOpen,
  } = useContext(ModalContext);

  const [categoriesResults, categoriesLoading, categoriesErrors] =
    useCategories(user?.uid);

  const [
    transactionResults,
    transactionResultsLoading,
    transactionResultsErrors,
  ] = useTransactions(user, dateFilter.startDate, dateFilter.endDate);

  const unpackedCategories = useMemo(() => {
    return unpack(categoriesResults?.docs);
  }, [categoriesResults]);

  const handleAddCategoryOpened = () => {
    setAddCategoryModalKey(addCategoryModalKey + 1);
    setAddCategoryOpen(true);
  };

  const handleManageCategoriesOpened = () => {
    setManageCategoriesModalKey(manageCategoriesModalKey + 1);
    setManageCategoriesOpen(true);
  };

  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const unpackedTransactions = useMemo(() => {
    const unpackedTransactions = unpack(transactionResults?.docs);

    return unpackedTransactions.map((transaction) => ({
      ...transaction,
      date: new Date(transaction.date.seconds * 1000),
    }));
  }, [transactionResults]);

  useEffect(() => {
    setFilteredTransactions(unpackedTransactions);
  }, [unpackedTransactions, dateFilter]);

  const [showDeletingTransactions, setShowDeletingTransactions] =
    useState(false);

  const handleTransactionCheckboxChanged = (transactionId, checked) => {
    if (checked) {
      setSelectedTransactions([...selectedTransactions, transactionId]);
    } else {
      setSelectedTransactions(
        selectedTransactions.filter((id) => id !== transactionId)
      );
    }
  };

  const handleModalOkClicked = async () => {
    await useDeleteTransactions(user, selectedTransactions);
    setSelectedTransactions([]);
  };

  return (
    <DefaultLayout
      title="Categorize"
      modals={
        <Modals
          categories={unpackedCategories}
          addCategoryKey={addCategoryModalKey}
          manageCategoriesModalKey={manageCategoriesModalKey}
          selectedTransactions={selectedTransactions}
          onOkClicked={handleModalOkClicked}
        />
      }
    >
      <CategorizeFilterBar
        dateFilter={dateFilter}
        onFilterSelected={setDateFilter}
        timeRangeFilters={allTimeFilters}
        categories={unpackedCategories}
        onAddCategoryOpened={handleAddCategoryOpened}
        onManageCategoriesOpened={handleManageCategoriesOpened}
        deleteTransactionsToggled={showDeletingTransactions}
        onDeleteTransactionsToggled={setShowDeletingTransactions}
        onDeleteTransactionsClicked={() => {
          setDeleteTransactionsOpen(true);
        }}
        sx={{ marginBottom: theme.spacing(3) }}
      />

      <Categorize
        categories={unpackedCategories}
        loading={categoriesLoading || transactionResultsLoading}
        transactions={filteredTransactions}
        showDeletingTransactions={showDeletingTransactions}
        selectedTransactions={selectedTransactions}
        onTransactionCheckboxChanged={handleTransactionCheckboxChanged}
      />
    </DefaultLayout>
  );
};

export default CategorizePage;
