import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import AppBar from "./AppBar";
import { MobileDrawer, RegularDrawer } from "./Drawer";
import List from "@mui/material/List";
import SignedInMenu from "../../components/menu/SignedInMenu";
import SubscriberMenu from "../../components/menu/SubscriberMenu";
import FooterText from "../../components/FooterText";
import RebudgetLogoText from "./RebudgetLogoText";
import { useAuthState } from "react-firebase-hooks/auth";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import settings from "../../settings/settings";
import SignInAndOut from "./SignInAndOut";
import AppContext from "../../context/appContext";
import { useContext } from "react";

function DefaultLayout(props) {
  const [user, loading, error] = useAuthState(getAuth(getApp()));
  const { children, modals, title } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const year = new Date().getFullYear();

  const { isSubscribed } = useContext(AppContext);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="absolute"
        sx={{
          background:
            "rgb(18,44,122); linear-gradient(180deg, rgba(18,44,122,1) 0%, rgba(33,77,166,1) 100%);",
        }}
      >
        <Toolbar
          sx={{
            // pr: "24px", // keep right padding when drawer closed
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              display: { xs: "block", sm: "none" },
              ...(!user && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <RebudgetLogoText />

          <SignInAndOut />
        </Toolbar>
      </AppBar>
      <MobileDrawer open={open} onDrawClosed={() => setOpen(false)} />
      <RegularDrawer
        variant="permanent"
        open={open}
        sx={{
          ...(!user && { display: "none" }),
        }}
      >
        <List component="nav" sx={{ marginTop: "64px" }}>
          {!isSubscribed && <SignedInMenu />}
          {isSubscribed && <SubscriberMenu />}
        </List>
      </RegularDrawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h1" gutterBottom>
            {title}
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              {children}
              <FooterText
                mt={theme.spacing(2)}
                text={`Email - ${settings.email}. Copyright ${year} © ${settings.appName}`}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {modals}
    </Box>
  );
}

export default DefaultLayout;
