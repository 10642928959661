import DefaultLayout from "../layouts/Default/DefaultLayout";
import settings from "../settings/settings";
import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PageSection from "../components/shared/PageSection";

const PrivacyPolicyPage = () => {
  const theme = useTheme();
  return (
    <DefaultLayout title="Privacy Policy">
      <Paper sx={{ p: theme.spacing(2) }}>
        <PageSection title="Questions / Feedback / Suggestions">
          <p>
            {`This Privacy Policy describes how MoneyTool.io ("we" or "our")
            collects, uses, and protects the personal information of its users
            ("you" or "users") when using our website. By accessing and using
            MoneyTool.io, you agree to the terms of this Privacy Policy.`}
          </p>

          <h2>1. Information Collected</h2>
          <p>
            We collect the following types of personal information from our
            users: email addresses and transaction details. Please note that we
            do not collect any banking information such as account or bank
            details.
          </p>

          <h2>2. Data Collection Methods</h2>
          <p>
            We collect personal information through user behavior and
            transactional information that gets uploaded to our website.
            Additionally, we collect website browsing behavior data.
          </p>

          <h2>3. Use of Information</h2>
          <p>
            The collected information is used solely to make the MoneyTool.io
            application work for you as an end user. We do not currently sell or
            share any data with third parties, except for cloud service
            providers that MoneyTool.io runs on (such as Google), which are
            essential for providing our services.
          </p>

          <h2>4. Cookies and Tracking Technologies</h2>
          <p>
            We use cookies and tracking technologies to provide you with an
            optimal site experience and enhance our services. By using
            MoneyTool.io, you consent to the use of these technologies.
          </p>

          <h2>5. Data Security</h2>
          <p>
            {`Data and security is taken seriously. Your personal information is stored
            and protected by the world's largest and most trusted cloud
            provider. However, please be aware that no method of transmission or
            storage is 100% secure, and we cannot guarantee the absolute
            security of your data.`}
          </p>

          <h2>6. User Choices and Controls</h2>
          <p>
            You have the right to access, modify, or delete your personal
            account details. If you wish to remove your personal account
            details, please send an email to help@moneytool.io, and we will
            assist you accordingly. Please note that email addresses are saved
            indefinitely.
          </p>

          <h2>7. Updates to the Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by sending an email to the address associated
            with your account. We encourage you to review this Privacy Policy
            periodically for any updates or modifications.
          </p>

          <h2>8. Contact Us</h2>
          <p>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy, please contact us at help@moneytool.io.
          </p>
        </PageSection>
      </Paper>
    </DefaultLayout>
  );
};

export default PrivacyPolicyPage;
