import paths from "../settings/firestorePaths";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { where, query } from "firebase/firestore";
import settings from "../settings/settings";

import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getApp } from "firebase/app";

const firestore = () => getFirestore(getApp());

const useCreateCheckoutSession = async (userId, price) => {
  if (!price) throw new Error("Price is required");

  const path = paths.stripeCheckoutSessions(userId);
  const ref = collection(firestore(), path);
  return await addDoc(ref, {
    price,
    success_url: settings.subscribeSuccessUri,
    cancel_url: window.location.origin,
  });
};

const useCheckoutSession = (ref) => {
  return useDocument(ref);
};

const useGetActiveSubscriptions = (userId) => {
  const path = paths.userSubscriptions(userId);
  const ref = collection(firestore(), path);
  const queryConstraints = [];
  queryConstraints.push(where("status", "in", ["trialing", "active"]));
  const q = query(ref, ...queryConstraints);
  return useCollection(q);
};

const useHasActiveSubscription = (userId) => {
  const [
    activeSubscriptions,
    activeSubscriptionsLoading,
    activeSubscriptionsError,
  ] = useGetActiveSubscriptions(userId);

  if (
    !userId ||
    activeSubscriptionsLoading ||
    activeSubscriptionsError ||
    !activeSubscriptions.docs
  ) {
    return [false, activeSubscriptionsLoading, activeSubscriptionsError];
  }

  return [
    true,
    // activeSubscriptions.docs.length > 0,
    activeSubscriptionsLoading,
    activeSubscriptionsError,
  ];
};

const useGetStripeLink = (userId) => {
  const [
    activeSubscriptions,
    activeSubscriptionsLoading,
    activeSubscriptionsError,
  ] = useGetActiveSubscriptions(userId);

  let stripeLink = null;

  if (!activeSubscriptionsLoading && !activeSubscriptionsError) {
    if (activeSubscriptions?.docs?.length > 0) {
      const subscription = activeSubscriptions.docs[0];
      stripeLink = subscription.data().stripeLink;
    }
  }

  return [stripeLink, activeSubscriptionsLoading, activeSubscriptionsError];
};

export {
  useCreateCheckoutSession,
  useCheckoutSession,
  useGetStripeLink,
  useHasActiveSubscription,
};
