import { format } from "date-fns";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const currency = (value) => formatter.format(value);

const unpack = (items) => {
  if (!items || items?.length === 0) return [];
  return items.map((item) => ({
    id: item.id,
    parentId: item.ref.parent.parent.id,
    ...item.data(),
  }));
};

const getMonthName = (date) => {
  return format(date, "MMMM");
};

const subscriptionPrices = {
  monthly: "price_1NC1X5GktABFGkmcuF4ucxAC",
  yearly: "price_1NC1XhGktABFGkmcsbTO36ma",
  business: "price_1NC1XzGktABFGkmcRmCDJwkN",
};

export { currency, unpack, getMonthName, subscriptionPrices };
