import filterOption from "../filters/filterOption";

const today = new Date();

const thisMonth = filterOption(
  "thisMonth",
  "This Month",
  new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1)),
  new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0)),
  false
);

const lastMonth = filterOption(
  "lastMonth",
  "Last Month",
  new Date(Date.UTC(today.getFullYear(), today.getMonth() - 1, 1)),
  new Date(Date.UTC(today.getFullYear(), today.getMonth(), 0)),
  true
);

const last3Months = filterOption(
  "lastThreeMonths",
  "Last 3 Months",
  new Date(Date.UTC(today.getFullYear(), today.getMonth() - 2, 1)),
  new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0)),
  false
);

const thisYear = filterOption(
  "thisYear",
  "This Year",
  new Date(Date.UTC(today.getFullYear(), 0, 1)),
  new Date(Date.UTC(today.getFullYear(), 11, 31)),
  false
);

const lastYear = filterOption(
  "lastYear",
  "Last Year",
  new Date(Date.UTC(today.getFullYear() - 1, 0, 1)),
  new Date(Date.UTC(today.getFullYear() - 1, 11, 31)),
  false
);

const allTimeFilters = [thisMonth, lastMonth, last3Months, thisYear, lastYear];

const justYearsFilters = [thisYear, lastYear];

export { allTimeFilters, justYearsFilters };
