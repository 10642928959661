import React, { useState, useMemo } from "react";
import { Button, Box } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useCSVImportSettings } from "../../hooks/useSettings";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import SkeletonForm from "./SkeletonForm";
import { useUploadCSVTransactions } from "../../hooks/useTransactions";

const LoadingView = () => <SkeletonForm numberOfRows={1} numberOfButtons={0} />;

const LoadedView = ({
  csvImportSettings,
  user,
  actionDisabled,
  onTransactionsAdded,
}) => {
  const [fileName, setFileName] = useState("");

  const handleFileUpload = async (e) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const { name } = file;
    setFileName(name);
    const numberOfTransactionsAdded = await useUploadCSVTransactions(
      user,
      file,
      csvImportSettings
    );
    const result = {
      fileName: name,
      numberOfTransactionsAdded,
    };
    onTransactionsAdded(result);
  };

  return (
    <>
      <Button
        component="label"
        variant="outlined"
        startIcon={<UploadFileIcon />}
        sx={{ marginRight: "1rem" }}
        disabled={actionDisabled}
      >
        Upload CSV
        <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
      </Button>
    </>
  );
};

const CSVFileUpload = ({ actionDisabled, onTransactionsAdded }) => {
  const [user, userLoading, userError] = useAuthState(getAuth());
  const [csvImportSettings, csvImportSettingsLoading, csvImportSettingsError] =
    useCSVImportSettings(user?.uid);

  const settings = useMemo(() => {
    return !csvImportSettings ? null : csvImportSettings.data();
  }, [csvImportSettings]);

  return (
    <>
      {csvImportSettingsLoading ? (
        <LoadingView />
      ) : (
        <LoadedView
          csvImportSettings={settings}
          user={user}
          actionDisabled={actionDisabled}
          onTransactionsAdded={onTransactionsAdded}
        />
      )}
    </>
  );
};

export default CSVFileUpload;
