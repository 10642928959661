import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

const MoonBeamBox = ({ children, ...rest }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        margin: "auto",
        mt: { xs: theme.spacing(5), md: theme.spacing(10) },
        border: "2px solid white;",
        borderRadius: "15px",
        backgroundColor: "#fff",
        boxShadow: "0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;",
        ...rest,
      }}
    >
      {children}
    </Box>
  );
};

export default MoonBeamBox;
