import React from "react";

const AppContext = React.createContext({
  products: [],
  dateFilter: null,
  setDateFilter: () => {},
  isSubscribed: false,
});

export default AppContext;
