import DefaultLayout from "../layouts/Default/DefaultLayout";
import settings from "../settings/settings";
import React from "react";
import { Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PageSection from "../components/shared/PageSection";
import { Link } from "react-router-dom";
import routes from "../settings/routes";

const FeedbackPage = () => {
  const theme = useTheme();
  return (
    <DefaultLayout title="Help">
      <Paper sx={{ p: theme.spacing(2) }}>
        <PageSection title={`How To Use ${settings.appName}`}>
          <ul>
            <li>
              Download CSV files from your bank with the transactions you want
              to use
            </li>
            <li>Go to the transactions tab and upload them</li>
            <li>
              After uploading, go to the Categorize tab to categorize your
              transactions
            </li>
            <li>Go to the reports tab to see categorized values</li>
          </ul>
        </PageSection>
        <PageSection title="Questions / Feedback / Suggestions">
          <Typography variant="body1" gutterBottom>
            If you have any questions, feedback, or suggestions, please email{" "}
            <strong>{`${settings.email}`}</strong>
          </Typography>
        </PageSection>
        <PageSection title="Privacy Policy">
          <Link to={routes.privacyPolicy}>Read the Privacy Policy</Link>
        </PageSection>
        <PageSection title="Terms of Service">
          <Link to={routes.termsOfService}>Read the Terms of Service</Link>
        </PageSection>
      </Paper>
    </DefaultLayout>
  );
};

export default FeedbackPage;
