import { Paper, Box } from "@mui/material";
import React from "react";

const FilterBar = ({
  contents,
  appliedFilters,
  filterMenuOptions,
  onAddCategoryOpened,
  onFilterSelected,
  onManageCategoriesOpened,
  categories,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {contents}
      </Paper>
      {appliedFilters}
    </Box>
  );
};

export default FilterBar;
