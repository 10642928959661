import DefaultLayout from "../layouts/Default/DefaultLayout";
import settings from "../settings/settings";
import React from "react";

const FeedbackPage = () => {
  return (
    <DefaultLayout title="Help">
      <h2>Questions/Feedback</h2>
      <p>
        If you have questions for feedback, please email{" "}
        <strong>{settings.email}</strong>
      </p>
      <h2>About</h2>
      <p>
        {`Zapier is a tool that allows you to get any data and push it into ${settings.appName}.`}
      </p>
      <p>Some examples:</p>
      <ul>
        <li>{`You could get transaction data from your favourite bank and then inject into ${settings.appName}`}</li>
        <li>{`You could get transaction data from an email and then inject into ${settings.appName}`}</li>
        <li>{`You could get transaction data from a .CSV and then inject into ${settings.appName}`}</li>
        <li>{`You could get transaction data from an Excel file and then inject into ${settings.appName}`}</li>
        <li>{`You could get transaction data from a Word document and then inject into ${settings.appName}`}</li>
      </ul>
      <h2>Privacy</h2>
      <p>
        Data is not currently shared with any third party. No identifying bank,
        account details are stored.
      </p>
      <p>
        The only data that is stored is the description of the sale, the amount
        and the date.
      </p>

      <h1>Zapier Integration</h1>
      <h2>Getting your API Key</h2>
      <p>First, find your API Key by clicking settings. Make note of it</p>
      <p>
        <img
          src="https://iili.io/HvaEx6v.jpg"
          alt="Hv0xwq7.jpg"
          border="0"
          width="100%"
        />
      </p>

      <h2>Setting up Zapier</h2>
      <p>Go to Zapier and create a new Zap.</p>
      <p>
        For a simple example, we will have one trigger (the start of the
        process) that calls an action.
      </p>
      <p>
        The trigger is what causes Zapier to start to do something. In this
        example we use JavaScript or Python as a trigger.
      </p>

      <h3>Create the trigger</h3>
      <p>Here we create a some fake transactions called First and Second</p>
      <p>You can think of this data as creating 2 transactions.</p>
      <h4>First Transaction</h4>
      <dl>
        <dt>Name</dt>
        <dd>First</dd>
        <dt>Amount</dt>
        <dd>24</dd>
        <dt>Date</dt>
        <dd>2022-01-01</dd>
      </dl>

      <h4>Second Transaction</h4>
      <dl>
        <dt>Name</dt>
        <dd>Second</dd>
        <dt>Amount</dt>
        <dd>34</dd>
        <dt>Date</dt>
        <dd>2022-01-02</dd>
      </dl>

      <img
        src="https://i.imgur.com/VRAbJI4.jpeg"
        alt="Example JavaScript code for Zapier"
        border="0"
        width="100%"
      />
      <img
        src="https://i.imgur.com/RLxXHRr.jpeg"
        alt="Test Success"
        border="0"
        width="100%"
      />

      <h3>Create the action</h3>
      <p>{`Next we create a ${settings.appName} action`}</p>
      <p>{`In the action, look for ${settings.appName}`}</p>
      <img src="https://i.imgur.com/RspRw90.jpeg" width="100%" />

      <p>Choose create Transactions event</p>
      <p>
        These would be useful when trying to see an average of how much you
        spend per month on Gas for example
      </p>
      <img
        src="https://iili.io/Hv0xhsS.jpg"
        alt="Hv0xhsS.jpg"
        border="0"
        width="100%"
      />

      <p>Add your User Id and API Key</p>
      <img
        src="https://iili.io/Hv0xXX2.jpg"
        alt="Hv0xXX2.jpg"
        border="0"
        width="100%"
      />

      <p>Add the transaction data</p>
      <p>Doing it like this will create 2 transactions in MoneyTool.io</p>
      <img
        src="https://iili.io/Hv0xkdu.jpg"
        alt="Hv0xkdu.jpg"
        border="0"
        width="100%"
      />

      <p>Test the action</p>
      <img
        src="https://iili.io/Hv0xv5b.jpg"
        alt="Hv0xv5b.jpg"
        border="0"
        width="100%"
      />
      <br />
    </DefaultLayout>
  );
};

export default FeedbackPage;
