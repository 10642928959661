import { Typography } from "@mui/material";
import React from "react";

const FooterText = ({ text, ...rest }) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...rest}>
      {text}
    </Typography>
  );
};

export default FooterText;
