import { Typography, Divider } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

const SettingsCard = ({ icon, title, children }) => {
  const theme = useTheme();
  const hyphenDelimitedTitle = title.replace(/\s+/g, "-").toLowerCase();

  return (
    <Card
      sx={{
        mb: theme.spacing(2),
      }}
    >
      <CardHeader
        sx={{ pl: theme.spacing(2), pr: theme.spacing(2), pt: 0, pb: 0 }}
        avatar={React.cloneElement(icon, {
          sx: {
            fontSize: "3rem",
            color: theme.palette.primary.main,
            ...icon.props.sx,
          },
        })}
        title={
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold" }}
            id={hyphenDelimitedTitle}
          >
            {title}
          </Typography>
        }
      />
      <Divider />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default SettingsCard;
