import React from "react";
import { Select, MenuItem } from "@mui/material";
import PropTypes from "prop-types";

const SingleTextDropDown = ({ options, handleSelectChanged, ...rest }) => {
  return (
    <Select onChange={handleSelectChanged} {...rest}>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SingleTextDropDown;

SingleTextDropDown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectChanged: PropTypes.func.isRequired,
};
