import React, { useContext, useMemo, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Divider,
  TextField,
  Grid,
} from "@mui/material";
import DefaultLayout from "../../layouts/Default/DefaultLayout";
import { useTheme } from "@mui/material/styles";
import { currency, getMonthName, unpack } from "../../helpers/helpers";
import AppContext from "../../context/appContext";
import { useCategories } from "../../hooks/useCategories";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import createMonthlySpendReport from "../../factories/monthlySpendReportFactory";
import { useTransactions } from "../../hooks/useTransactions";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useMediaQuery } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import SingleTextDropDown from "../../components/shared/SingleTextDropDown";
const { startOfMonth, endOfMonth } = require("date-fns");

const Month = ({ date, total }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mb: theme.spacing(1),
      }}
    >
      <Typography variant="body1">{getMonthName(date)}</Typography>
      <Typography variant="body1">{currency(total)}</Typography>
    </Box>
  );
};

const Year = ({ year, ...rest }) => {
  const theme = useTheme();
  return (
    <Paper sx={{ p: theme.spacing(2), mt: theme.spacing(2) }} {...rest}>
      <Typography variant="h3">{year.date}</Typography>
      <Divider sx={{ mb: theme.spacing(2) }} />
      {year.months.map((month) => (
        <Month key={month.date} {...month} />
      ))}
    </Paper>
  );
};

const Summary = ({ total, monthlyAverage }) => {
  const theme = useTheme();
  return (
    <Paper sx={{ p: theme.spacing(2), mb: theme.spacing(2) }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h3">Total</Typography>
        <Typography
          variant="h3"
          sx={{ fontWeight: theme.typography.fontWeightBold }}
        >
          {currency(total)}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h3">Monthly Average</Typography>
        <Typography
          variant="h3"
          sx={{ fontWeight: theme.typography.fontWeightBold }}
        >
          {currency(monthlyAverage)}
        </Typography>
      </Box>
    </Paper>
  );
};

const NoTransactionsView = () => {
  const theme = useTheme();
  return (
    <Paper sx={{ p: theme.spacing(2) }}>
      <Typography variant="body1" sx={{}}>
        No transactions for the selected category and time period. Try changing
        the filters
      </Typography>
    </Paper>
  );
};

const Report = ({ monthlySpendReport }) => {
  const showReport = monthlySpendReport.years.length !== 0;
  return (
    <>
      {!showReport && <NoTransactionsView />}
      {showReport &&
        monthlySpendReport.years.map((year) => <Year key={year} year={year} />)}
    </>
  );
};

const MonthlySpendReport = () => {
  const DefaultCategory = "All";
  const theme = useTheme();

  const today = new Date();
  const defaultStartDate = startOfMonth(today);
  const defaultEndDate = endOfMonth(today);

  const [user, userLoading, userError] = useAuthState(getAuth());
  const [categoriesResults, categoriesLoading, categoriesErrors] =
    useCategories(user?.uid);

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [selectedCategory, setSelectedCategory] = useState(DefaultCategory);

  const categoryQueryItem = useMemo(() => {
    if (selectedCategory === DefaultCategory) return null;
    return selectedCategory;
  }, [selectedCategory]);

  const [
    transactionResults,
    transactionResultsLoading,
    transactionResultsErrors,
  ] = useTransactions(user, startDate, endDate);

  const unpackedCategories = useMemo(() => {
    return unpack(categoriesResults?.docs);
  }, [categoriesResults]);

  const unpackedTransactions = useMemo(() => {
    const unpackedTransactions = unpack(transactionResults?.docs);

    const transactionsThatHaveCategories = unpackedTransactions.filter(
      (transaction) => transaction.categoryId
    );

    const transactionsThatMatchCategory =
      selectedCategory == DefaultCategory
        ? transactionsThatHaveCategories
        : transactionsThatHaveCategories.filter(
            (transaction) => transaction.categoryId === selectedCategory
          );

    return transactionsThatMatchCategory.map((transaction) => ({
      ...transaction,
      date: new Date(transaction.date.seconds * 1000),
    }));
  }, [transactionResults, selectedCategory]);

  const monthlySpendReport = useMemo(() => {
    return createMonthlySpendReport(unpackedTransactions, startDate, endDate);
  }, [unpackedTransactions, startDate, endDate]);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const categoryNames = useMemo(() => {
    if (!unpackedCategories) return [];
    return [
      DefaultCategory,
      ...unpackedCategories.map((category) => category.name),
    ];
  }, [unpackedCategories]);

  const handleCategoryChanged = (e) => {
    setSelectedCategory(e?.target?.value);
  };

  return (
    <DefaultLayout title="Monthly Spend Report">
      <Paper
        sx={{
          p: theme.spacing(2),
          mb: theme.spacing(2),
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <SingleTextDropDown
          value={selectedCategory}
          label="Category"
          options={categoryNames}
          handleSelectChanged={handleCategoryChanged}
          sx={{
            marginRight: theme.spacing(2),
          }}
        />
        <DesktopDatePicker
          label="Start"
          inputFormat="MM/DD/YYYY"
          value={startDate}
          onChange={setStartDate}
          renderInput={(params) => <TextField {...params} />}
          sx={{
            marginRight: theme.spacing(2),
            display: isMobile ? "none" : "block",
          }}
        />
        <MobileDatePicker
          label="Start"
          inputFormat="MM/DD/YYYY"
          value={startDate}
          onChange={setStartDate}
          renderInput={(params) => <TextField {...params} />}
          sx={{
            marginRight: theme.spacing(2),
            display: isMobile ? "block" : "none",
          }}
        />
        <DesktopDatePicker
          label="End"
          inputFormat="MM/DD/YYYY"
          value={endDate}
          onChange={setEndDate}
          renderInput={(params) => <TextField {...params} />}
          sx={{
            display: isMobile ? "none" : "block",
          }}
        />
        <MobileDatePicker
          label="End"
          inputFormat="MM/DD/YYYY"
          value={endDate}
          onChange={setEndDate}
          renderInput={(params) => <TextField {...params} />}
          sx={{
            display: isMobile ? "block" : "none",
          }}
        />
      </Paper>
      <Summary
        total={monthlySpendReport.total}
        monthlyAverage={monthlySpendReport.monthlyAverage}
        sx={{
          mb: theme.spacing(2),
        }}
      />
      <Report monthlySpendReport={monthlySpendReport} />
    </DefaultLayout>
  );
};

export default MonthlySpendReport;
