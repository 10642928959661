import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BarChartIcon from "@mui/icons-material/BarChart";
import PointOfSale from "@mui/icons-material/PointOfSale";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTheme } from "@mui/material/styles";
import { useSignOut } from "react-firebase-hooks/auth";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useAuthState } from "react-firebase-hooks/auth";
import { useMemo } from "react";
import { useContext } from "react";
import AppContext from "../../context/appContext";
import ListIcon from "@mui/icons-material/List";
import HelpIcon from "@mui/icons-material/Help";
import BoltIcon from "@mui/icons-material/Bolt";
import routes from "../../settings/routes";

const Menu = () => {
  const [signOut, loading, error] = useSignOut(getAuth(getApp()));
  const theme = useTheme();
  const [user, userLoading, userError] = useAuthState(getAuth());
  const { products } = useContext(AppContext);

  const handleSignOutClicked = () => {
    signOut();
    // Fire a sign out event
    const analytics = getAnalytics();
    logEvent(analytics, "sign_out");
  };

  const monthlySpendingEnabled = (products) =>
    products?.some((product) => product.id === "monthly-spending");

  const showMonthlySpending = useMemo(() => {
    return monthlySpendingEnabled(products);
  }, [products]);

  const showReports = useMemo(() => {
    return monthlySpendingEnabled(products);
  }, [products]);

  return (
    <>
      <ListItemButton component={Link} to={routes.dashboard}>
        <ListItemIcon>
          <OtherHousesIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      {showMonthlySpending && (
        <ListItemButton component={Link} to={routes.transactions}>
          <ListItemIcon>
            <PointOfSale />
          </ListItemIcon>
          <ListItemText primary="Transactions" />
        </ListItemButton>
      )}
      {showMonthlySpending && (
        <ListItemButton component={Link} to={routes.categorize}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Categorize" />
        </ListItemButton>
      )}
      {showReports && (
        <ListItemButton component={Link} to={routes.reports}>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItemButton>
      )}
      <ListItemButton component={Link} to={routes.help}>
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary="Help" />
      </ListItemButton>

      <Divider />

      <ListItemButton component={Link} to={routes.zapierConnections}>
        <ListItemIcon>
          <BoltIcon />
        </ListItemIcon>
        <ListItemText primary="Zapier Connections" />
      </ListItemButton>
      <ListItemButton component={Link} to={routes.zapierIntegration}>
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary="Zapier Help" />
      </ListItemButton>

      <Divider />
      <ListItemButton component={Link} to={routes.settings}>
        <ListItemIcon>
          <SettingsApplicationsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>
      <Divider />
      <ListItemButton
        sx={{ mt: theme.spacing(5) }}
        component={Link}
        onClick={handleSignOutClicked}
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Sign Out" />
      </ListItemButton>
    </>
  );
};

export default Menu;
