import React from "react";
import { Formik, Form, Field } from "formik";
const loginCredentialsSchema = require("../../vaildation/loginCredentialsSchema");
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { getApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Toaster from "../shared/Toaster";
import { useNavigate } from "react-router-dom";

const EmailPasswordSignUp = () => {
  const theme = useTheme();
  const auth = getAuth(getApp());
  const [resetEmailSuccessOpen, setResetEmailSuccessOpen] = useState(false);
  const [resetEmailFailedOpen, setResetEmailFailedOpen] = useState(false);
  const navigate = useNavigate();

  const resetPassword = (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setResetEmailSuccessOpen(true);
      })
      .catch((error) => {
        setResetEmailFailedOpen(true);
      });
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={loginCredentialsSchema}
    >
      {({ errors, touched, values }) => (
        <Form>
          <Field name="email">
            {({ field }) => (
              <TextField
                {...field}
                id="email"
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                error={errors.email && touched.email}
                helperText={errors.email}
              />
            )}
          </Field>

          <Button
            disabled={values.email === "" || !!errors.email}
            aria-label="Save"
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              resetPassword(values.email);
            }}
          >
            Continue
          </Button>

          <Toaster
            message="Password reset email sent!"
            type="success"
            open={resetEmailSuccessOpen}
            onClosed={() => setResetEmailSuccessOpen(false)}
          />

          <Toaster
            message="Ran into issues sending password reset email."
            type="error"
            open={resetEmailFailedOpen}
            onClosed={() => setResetEmailFailedOpen(false)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EmailPasswordSignUp;
