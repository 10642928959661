import { useNavigate } from "react-router-dom";
import routes from "../settings/routes";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

const useSignOut = () => {
  const navigate = useNavigate();

  const signOutCustom = async () => {
    const auth = getAuth(getApp());
    await auth.signOut();
    navigate(routes.home);
    const analytics = getAnalytics();
    logEvent(analytics, "sign_out");
  };

  return signOutCustom;
};

export { useSignOut };
