import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    brand: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    hero: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontWeight: 300,
    },
    special: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
  },
  palette: {
    brand: {
      main: "#2a46aa",
    },
    primary: {
      main: "#2a46aa",
      // main: '#ed1c24',
      light: "#ff5f4e",
      dark: "b20000",
    },
    secondary: {
      main: "#1f3994",
      // main: '#d32f2f',
      light: "#ff6659",
      dark: "#9a0007",
    },
    neutral: {
      dark: "#555555",
      medium: "#6f6f6f",
      light: "#e0e0e0",
    },
    white: {
      main: "#ffffff",
    },
  },
});

theme.typography.h1 = {
  fontWeight: 800,
  letterSpacing: "-0.03em",
  color: theme.palette.neutral.dark,
};

theme.typography.h2 = {
  color: theme.palette.neutral.dark,
};

theme.typography.h3 = {
  fontWeight: 400,
  color: theme.palette.neutral.dark,
};

theme.typography.h4 = {
  color: theme.palette.neutral.dark,
};

theme.typography.h5 = {
  color: theme.palette.neutral.dark,
};

export default theme;
