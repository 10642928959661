import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  useCSVImportSettings,
  useUpdateCSVImportSettings,
} from "../../hooks/useSettings";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { Formik, Form, Field } from "formik";
import SkeletonForm from "../../components/shared/SkeletonForm";
import Hint from "../../components/shared/Hint";

const LoadingView = () => <SkeletonForm numberOfRows={3} numberOfButtons={1} />;

const LoadedView = ({ user, csvImportSettings }) => {
  const theme = useTheme();

  const [formValues, setFormValues] = useState({
    dateColumnNo: 0,
    descriptionColumnNo: 0,
    amountColumnNo: 0,
    csvHasHeaderRow: false,
  });

  useEffect(() => {
    if (csvImportSettings) {
      const dateColumnNo = csvImportSettings.data()?.dateColumnNo || 0;
      const descriptionColumnNo =
        csvImportSettings.data()?.descriptionColumnNo || 0;
      const amountColumnNo = csvImportSettings.data()?.amountColumnNo || 0;
      const csvHasHeaderRow =
        csvImportSettings.data()?.csvHasHeaderRow || false;

      setFormValues({
        dateColumnNo,
        descriptionColumnNo,
        amountColumnNo,
        csvHasHeaderRow,
      });
    }
  }, [csvImportSettings]);

  const handleSaveClicked = async (data) => {
    const {
      dateColumnNo,
      descriptionColumnNo,
      amountColumnNo,
      csvHasHeaderRow,
    } = data;
    await useUpdateCSVImportSettings(user?.uid, {
      dateColumnNo,
      descriptionColumnNo,
      amountColumnNo,
      csvHasHeaderRow,
    });
  };

  return (
    <Formik
      initialValues={formValues}
      onSubmit={handleSaveClicked}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange }) => (
        <Form>
          <Typography variant="body1" sx={{ mb: theme.spacing(2) }}>
            If you are uploading transactions in a CSV file, please specify
            which column numbers contains the date, description and debit
            amount. (1 is the first column)
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field name="csvHasHeaderRow">
                {({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={values.csvHasHeaderRow}
                        onChange={handleChange}
                      />
                    }
                    label="CSV Has Header Row"
                  />
                )}
              </Field>
              <Hint
                text="Look at the CSV. If the first row is the names of the columns e.g. Date, Description, Credit, Debit etc.. Check this"
                sx={{
                  position: "relative",
                  top: "7px",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field name="dateColumnNo">
                {({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label="Date Column No"
                    type="number"
                    value={values.dateColumnNo}
                    onChange={handleChange}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="descriptionColumnNo">
                {({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label="Description Column No"
                    type="number"
                    value={values.descriptionColumnNo}
                    onChange={handleChange}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="amountColumnNo">
                {({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label="Debit Amount Column No"
                    type="number"
                    value={values.amountColumnNo}
                    onChange={handleChange}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: theme.spacing(2),
            }}
          >
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const CSVImport = () => {
  const [user, userLoading, userError] = useAuthState(getAuth());
  const [csvImportSettings, csvImportSettingsLoading, csvImportSettingsError] =
    useCSVImportSettings(user?.uid);

  return (
    <>
      {(userLoading || csvImportSettingsLoading) && <LoadingView />}
      {!userLoading &&
        !csvImportSettingsLoading &&
        !!user &&
        !!csvImportSettings && (
          <LoadedView user={user} csvImportSettings={csvImportSettings} />
        )}
    </>
  );
};

export default CSVImport;
