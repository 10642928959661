import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import routes from "../../settings/routes";
import { useSignOut } from "../../hooks/useSiteNavigation";

const SignedOutOptions = () => {
  const navigate = useNavigate();

  const handleSignInClicked = () => {
    navigate(routes.signIn);
  };

  const handleSignUpClicked = () => {
    navigate(routes.signUp);
  };

  const theme = useTheme();

  return (
    <Box
      sx={{
        gap: theme.spacing(2),
        display: "flex",
      }}
    >
      <Button variant="outlined" color="inherit" onClick={handleSignInClicked}>
        Sign In
      </Button>
      <Button
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
        }}
        variant="contained"
        color="primary"
        onClick={handleSignUpClicked}
      >
        Get Started - FREE
      </Button>
    </Box>
  );
};

const SignedInOptions = () => {
  const handleSignOutClicked = useSignOut();

  return (
    <Button variant="outlined" color="inherit" onClick={handleSignOutClicked}>
      Sign Out
    </Button>
  );
};

const SignInAndOut = () => {
  const [user, userLoading, userError] = useAuthState(getAuth());

  const userIsLoggedIn = useMemo(() => {
    return user && !userLoading && !userError;
  }, [user]);

  return (
    <Box>{userIsLoggedIn ? <SignedInOptions /> : <SignedOutOptions />}</Box>
  );
};

export default SignInAndOut;
