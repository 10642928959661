import Feature from "./Feature";
import { Typography, Grid } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

const Outcomes = ({ ...rest }) => {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h1">Outcomes</Typography>
        <Feature
          title="Save Money"
          imageSource="https://placehold.co/100x200"
          sx={{ mt: theme.spacing(3) }}
        >
          <Typography variant="body1">
            By being aware of what you are spending money on you can save more
            money
          </Typography>
        </Feature>

        <Feature
          title="Identify Fraudulent Transactions"
          imageSource="https://placehold.co/100x200"
        >
          <Typography variant="body1">
            By manually categorizing your transactions you can see any
            suspicious looking transactions
          </Typography>
        </Feature>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h1">Features</Typography>
        <Feature
          title="Categorize"
          imageSource="https://placehold.co/100x200"
          sx={{ mt: theme.spacing(3) }}
        >
          <Typography variant="body1">
            By manually categorizing your transactions you get to feel where
            your money is going
          </Typography>
        </Feature>

        <Feature
          title="Monthly Spend Reports"
          imageSource="https://placehold.co/100x200"
        >
          <Typography variant="body1">
            See what you are spending on for each category
          </Typography>
        </Feature>

        <Feature
          title="Data Import Options"
          imageSource="https://i.imgur.com/vSFypB2.jpeg"
        >
          <Typography variant="body1">
            Import Transaction data from .CSV file and through Zapier
          </Typography>
        </Feature>

        <Feature title="Secure" imageSource="https://placehold.co/100x200">
          <Typography variant="body1">
            Secured by the some of the largest infrastructure providers in the
            world
          </Typography>
        </Feature>
      </Grid>
    </Grid>
  );
};

export default Outcomes;
