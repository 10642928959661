import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

const TopText = () => {
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="body1"
        sx={{
          mt: {
            xs: theme.spacing(2),
            sm: theme.spacing(5),
            lg: theme.spacing(5),
          },
          mb: theme.spacing(5),
          fontSize: theme.typography.fontSize * 1.25,
          textAlign: "center",
          fontFamily: theme.typography.special,
        }}
      >
        {`Do you feel that you aren't getting ahead and you don't know
          why? Would you like to keep more money for yourself?`}
      </Typography>
    </Box>
  );
};

export default TopText;
