import { Paper, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Grid, Skeleton } from "@mui/material";
import { format } from "date-fns";
import { currency } from "../../helpers/helpers";
import CategoryPicker from "./CategoryPicker";
import { useMemo } from "react";
import React from "react";
import { useCategorizeTransaction } from "../../hooks/useTransactions";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";

const buildCategoryLabels = (categories) =>
  categories.map((category) => ({
    id: category.id,
    label: category.name,
  }));

const Loading = () => {
  const theme = useTheme();

  return (
    <>
      <Skeleton
        variant="rectangular"
        height={118}
        sx={{ marginBottom: theme.spacing(2) }}
      />
      <Skeleton
        variant="rectangular"
        height={118}
        sx={{ marginBottom: theme.spacing(2) }}
      />
      <Skeleton
        variant="rectangular"
        height={118}
        sx={{ marginBottom: theme.spacing(2) }}
      />
    </>
  );
};

const Loaded = ({
  transactions = [],
  categories = [],
  showDeletingTransactions,
  selectedTransactions,
  onTransactionCheckboxChanged,
}) => {
  const theme = useTheme();
  const [user, userLoading, userError] = useAuthState(getAuth());

  const categoryLabels = useMemo(() => {
    return buildCategoryLabels(categories);
  }, [categories]);

  const handleCategoryChanged = async (transaction, categoryId) => {
    await useCategorizeTransaction(user, transaction, categoryId);
  };

  const matchesMediumUp = useMediaQuery(theme.breakpoints.up("md"));

  const showSelectTransaction = useMemo(() => {
    return showDeletingTransactions || matchesMediumUp;
  }, [showDeletingTransactions, matchesMediumUp]);

  const handleCheckboxChanged = (transactionId, event) => {
    onTransactionCheckboxChanged(transactionId, event.target.checked);
  };

  return (
    <Box>
      <Paper>
        <Grid container>
          <Grid
            container
            sx={{ backgroundColor: "#6c6c6c", color: "#ffffff" }}
            display={{ xs: "none", lg: "flex" }}
            columns={24}
          >
            <Grid item display="flex" xs={1}>
              &nbsp;
            </Grid>
            <Grid item xs={23}>
              <Grid container>
                <Grid item xs={2} p={theme.spacing(1)}>
                  <Box sx={{ fontWeight: "bold" }}>Date</Box>
                </Grid>
                <Grid item xs={5} p={theme.spacing(1)}>
                  <Box sx={{ fontWeight: "bold" }}>Name</Box>
                </Grid>
                <Grid item xs={1} p={theme.spacing(1)}>
                  <Box sx={{ fontWeight: "bold" }}>Amount</Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {transactions
            .sort((a, b) => b.date - a.date)
            .map((transaction) => (
              <Grid
                container
                key={transaction.id}
                padding={theme.spacing(1)}
                columns={24}
              >
                <Grid
                  item
                  xs={4}
                  lg={1}
                  sx={{
                    display: {
                      xs: showSelectTransaction ? "flex" : "none",
                      lg: "block",
                    },
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    tabIndex={-1}
                    size={matchesMediumUp ? "medium" : "large"}
                    color="error"
                    checked={selectedTransactions.includes(transaction.id)}
                    onChange={(event) => {
                      handleCheckboxChanged(transaction.id, event);
                    }}
                  />
                </Grid>
                <Grid item xs={showSelectTransaction ? 20 : 24} lg={23}>
                  <Grid container>
                    <Grid
                      item
                      xs={9}
                      lg={2}
                      pt={{ xs: theme.spacing(1) }}
                      px={{ xs: theme.spacing(1) }}
                    >
                      <Box>
                        <Box
                          text="Date:"
                          sx={{
                            fontWeight: "bold",
                            display: { xs: "inline-block", lg: "none" },
                            marginRight: theme.spacing(1),
                          }}
                        >
                          Date:
                        </Box>
                        <Box sx={{ display: "inline-block" }}>
                          {format(transaction.date, "dd-MM-yyyy hh:mm")}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      lg={5}
                      px={{ xs: theme.spacing(1) }}
                      pt={{ md: theme.spacing(1) }}
                    >
                      <Box>
                        <Box
                          sx={{
                            fontWeight: "bold",
                            display: { xs: "inline-block", lg: "none" },
                            marginRight: theme.spacing(1),
                          }}
                        >
                          Description:
                        </Box>
                        <Box sx={{ display: "inline-block" }}>
                          {transaction.description}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      lg={1}
                      px={{ xs: theme.spacing(1) }}
                      pt={{ md: theme.spacing(1) }}
                      textAlign={{ xs: "right", md: "left" }}
                    >
                      <Box>
                        <Box
                          sx={{ display: "inline-block", fontWeight: "bold" }}
                        >
                          {currency(transaction.amount)}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      pt={{ xs: theme.spacing(1) }}
                      pb={{ xs: theme.spacing(1) }}
                      px={{ xs: theme.spacing(1) }}
                    >
                      <Box>
                        <CategoryPicker
                          selectedCategoryId={transaction.categoryId}
                          categories={categoryLabels}
                          onCategoryChanged={(categoryId) =>
                            handleCategoryChanged(transaction, categoryId)
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Paper>
    </Box>
  );
};

const Categorize = ({ loading, ...rest }) => {
  return (
    <>
      {loading && <Loading />}
      {!loading && <Loaded {...rest} />}
    </>
  );
};

export default Categorize;
