const { isWithinInterval, startOfMonth, endOfMonth } = require("date-fns");

const getDistinctMonthStartDates = (startDate, endDate) => {
  const firstOfMonthDates = [];
  let currentDate = startOfMonth(startDate);

  while (currentDate <= endOfMonth(endDate)) {
    firstOfMonthDates.push(new Date(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return firstOfMonthDates;
};

const buildMonths = (transactions, startDate, endDate) => {
  let months = [];

  const distinctMonthsStartDates = getDistinctMonthStartDates(
    startDate,
    endDate
  );

  distinctMonthsStartDates.forEach((monthStartDate) => {
    const monthEndDate = endOfMonth(monthStartDate);

    const monthTransactions = transactions.filter((transaction) =>
      isWithinInterval(transaction.date, {
        start: monthStartDate,
        end: monthEndDate,
      })
    );

    const total = monthTransactions.reduce((a, b) => a + b.amount, 0);

    months.push({
      date: monthStartDate,
      total,
      transactions: monthTransactions,
      totalNumberOfTransactions: monthTransactions.length,
    });
  });

  return months;
};

const buildYears = (months) => {
  let years = [];

  months.forEach((month) => {
    let year = month.date.getFullYear();

    let isYearFound = years.some((y) => y.date === year);

    if (!isYearFound) {
      years.push({
        date: year,
        months: [],
      });
    }

    let yearIndex = years.findIndex((y) => y.date === year);

    years[yearIndex].months.push(month);
  });

  return years;
};

const createMonthlySpendReport = (transactions, startDate, endDate) => {
  const months = buildMonths(transactions, startDate, endDate) || [];

  const total = transactions.reduce((a, b) => a + b.amount, 0);
  const monthlyAverage =
    months.length === 0
      ? 0
      : months.reduce((a, b) => a + b.total, 0) / months.length;
  const years = buildYears(months);

  return {
    total,
    monthlyAverage,
    years,
  };
};

export default createMonthlySpendReport;
