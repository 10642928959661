import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { Button } from "@mui/material";

const DeleteButton = ({ ...rest }) => (
  <Button
    fullWidth
    variant="outlined"
    color="error"
    startIcon={<DeleteIcon />}
    {...rest}
  >
    Delete Transactions
  </Button>
);

export default DeleteButton;
