export default {
  home: "/",
  signIn: "/sign-in",
  signUp: "/sign-up",
  signOut: "/sign-out",
  transactions: "/transactions",
  categorize: "/categorize",
  reports: "/reports",
  settings: "/settings",
  help: "/help",
  zapierConnections: "/integration/zapier/connections",
  zapierIntegration: "/integration/zapier",
  dashboard: "/dashboard",
  feedback: "/feedback",
  subscribe: "/subscribe",
  resetPassword: "/reset-password",
  privacyPolicy: "/privacy-policy",
  termsOfService: "/terms-of-service",
  ynabComparison: "/ynab-comparison",
};
