import { Grid, Skeleton } from "@mui/material";
import HouseholdSpendingCategoriesProduct from "../../components/seed/HouseholdSpendingCategoriesProduct";
import DefaultLayout from "../../layouts/Default/DefaultLayout";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import Products from "../../components/dashboard/Products";
import React, { useMemo } from "react";
import { useMonthlySpendingProduct } from "../../hooks/useProducts";

const LoadingSeedData = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Skeleton variant="rectangular" height={300} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Skeleton variant="rectangular" height={300} />
      </Grid>
    </Grid>
  );
};

const LoadedSeedData = ({ disabled }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <HouseholdSpendingCategoriesProduct disabled={disabled} />
      </Grid>
    </Grid>
  );
};

const DashboardPage = () => {
  const [user, userLoading, userError] = useAuthState(getAuth());
  const [
    monthlySpendingProduct,
    monthlySpendingProductLoading,
    monthlySpendingProductErrors,
  ] = useMonthlySpendingProduct(user?.uid);

  const disableAddingProduct = useMemo(() => {
    return monthlySpendingProduct?.exists();
  }, [monthlySpendingProduct]);

  return (
    <DefaultLayout title="Dashboard">
      <Products>
        {monthlySpendingProductLoading ? (
          <LoadingSeedData />
        ) : (
          <LoadedSeedData disabled={disableAddingProduct} />
        )}
      </Products>
    </DefaultLayout>
  );
};

export default DashboardPage;
