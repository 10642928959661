import { useCollection } from "react-firebase-hooks/firestore";
import {
  collection,
  doc,
  query,
  where,
  getFirestore,
  writeBatch,
  setDoc,
  addDoc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import { getApp } from "firebase/app";
import paths from "../settings/firestorePaths";

const defaultCategories = [
  "Groceries",
  "Home Maintenance",
  "Transportation",
  "Utilities",
  "Healthcare",
  "Pets",
  "Miscellaneous",
  "Taxes",
  "Shopping",
  "Gas",
  "Mortgage",
  "Rent",
  "Rental Properties",
  "Daycare",
  "Bank Fees",
  "Auto Insurance",
  "Parking",
  "Online Subscriptions",
  "Fast Food",
  "Unknown",
  "Dining Out",
  "Dental",
  "Household",
  "Clothing",
  "Mobile Phone",
].map((category) => ({ name: category }));

const getFirestoreInstance = () => getFirestore(getApp());

const useCategories = (userId) => {
  const path = paths.categories(userId);
  const categoriesRef = collection(getFirestoreInstance(), path);
  const q = query(categoriesRef, orderBy("name"));
  return useCollection(q);
};

const useAddCategory = (userId) => async (categoryName) => {
  const path = paths.category(userId, categoryName);
  const categoriesRef = doc(getFirestoreInstance(), path);
  await setDoc(categoriesRef, { name: categoryName });
};

const useSeedHouseholdCategories = (userId) => async () => {
  const firestore = getFirestoreInstance();
  const path = paths.categories(userId);
  const categoriesRef = collection(firestore, path);

  const batch = writeBatch(firestore);
  defaultCategories.forEach((category) => {
    const categoryRef = doc(categoriesRef, category.name);
    batch.set(categoryRef, category);
  });

  await batch.commit();
};

const useDeleteCategory = (userId) => async (categoryId) => {
  const firestore = getFirestoreInstance();
  const path = paths.categories(userId);
  const categoriesRef = doc(firestore, path, categoryId);
  await deleteDoc(categoriesRef);
};

export {
  useCategories,
  useSeedHouseholdCategories,
  useAddCategory,
  useDeleteCategory,
};
