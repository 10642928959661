import React from "react";
import { Button, Paper, Typography, Box, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const MembershipCard = ({
  name,
  hasTrialPeriod = true,
  trialPeriodLength = 30,
  frequency = "month",
  price,
  handleClick,
  icon,
  disabled,
  sx,
  children,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Paper sx={sx} {...rest}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box sx={{ p: theme.spacing(2), display: "flex" }}>
          <Typography variant="h6">{name}</Typography>
          {icon}
        </Box>
        <Divider />
        <Box p={theme.spacing(2)}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {hasTrialPeriod && (
              <Box>
                <Typography variant="h4">
                  Free trial for {trialPeriodLength} days
                </Typography>
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: theme.typography.fontSize * 1,
                      fontWeight: "initial",
                    }}
                  >
                    then
                  </Typography>
                </Box>
              </Box>
            )}

            <Typography
              variant="h2"
              sx={{
                fontWeight: "bold",
                fontSize: theme.typography.fontSize * 3,
                textAlign: "center",
              }}
            >
              ${price}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                alignSelf: "flex-end",
                fontSize: theme.typography.fontSize * 1,
                position: "relative",
                top: -10,
              }}
            >
              /{frequency}
            </Typography>
          </Box>

          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            onClick={handleClick}
            fullWidth
          >
            Free Trial
          </Button>
          <Divider
            sx={{
              my: theme.spacing(2),
            }}
          />
          {children}
        </Box>
      </Box>
    </Paper>
  );
};

export default MembershipCard;
